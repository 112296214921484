const VendorList = [
  {
    id: 1,
    companyName: "A SQUARE CONSTRUCTIONS Pro Punita Joshi",
    contactPerson: "Punita Joshi",
    phone: "9907303373",
    email: "punitajoshi16@gmail.com",
  },
  {
    id: 2,
    companyName: "AAROHI SOLARS Pro Kapil Mangal",
    contactPerson: "Kapil Mangal",
    phone: "9827040844",
    email: "aarohisolars@gmail.com",
  },
  {
    id: 3,
    companyName: "Aditi Green Energy Solutions Pro Ashish Agrawal",
    contactPerson: "Ashish Dixit",
    phone: "9713748575",
    email: "aditigreenenergy@gmail.com",
  },
  {
    id: 4,
    companyName: "Agrawal Developers Pro Chayan Agrawal",
    contactPerson: "Chayan Agrawal",
    phone: "8770203079",
    email: "adsolar17@gmail.com",
  },
  {
    id: 5,
    companyName: "AIRTRONICS",
    contactPerson: "Vivek Rawat",
    phone: "9619900444",
    email: "airtronics99@gmail.com",
  },
  {
    id: 6,
    companyName: "AMPLUS KN ONE POWER PRIVATE LIMITED",
    contactPerson: "ROHIT KHANDUJA",
    phone: "9873552433",
    email: "airtronics99@gmail.com",
  },
  {
    id: 7,
    companyName: "ARUN CHUGH SOLAR",
    contactPerson: "Arun Chugh",
    phone: "9893366903",
    email: "arunchgh1@gmail.com",
  },
  {
    id: 8,
    companyName: "ASIA AKSHAY URJA SHOP Pro Shubham Dharava",
    contactPerson: "Shubham Dharwa",
    phone: "9179845510",
    email: "akshayurja93@gmail.com",
  },
  {
    id: 9,
    companyName: "ASTRALEUS SERVICES PRIVATE LIMITED",
    contactPerson: "KRISHNA THAKUR",
    phone: "9303854400",
    email: "kkthakur1985@gmail.com",
  },
  {
    id: 10,
    companyName: "AUTOSYS INDORE Pro Ravindra Sharma",
    contactPerson: "Ravindra Sharma",
    phone: "9993827008",
    email: "ravindra@autosysindore.com",
  },
  {
    id: 11,
    companyName:
      "BARWANI INFRA DEVELOPERS & SUPPLIERS BIDS Pro Mo. Arshi Sheikh",
    contactPerson: "MOHAMMED ARSHI SHEIKH",
    phone: "9406712345",
    email: "bids.barwani@gmail.com",
  },
  {
    id: 12,
    companyName: "BHANDARI SOLAR Pro Akash Bhandari",
    contactPerson: "Akash Bhandari",
    phone: "8878665511",
    email: "bhandarisolar2022@gmail.com",
  },
  {
    id: 13,
    companyName: "Bhaskar Akshay Urja Shop Pro Anil Bhardwaj",
    contactPerson: "Anil Bhardwaj",
    phone: "9826234499",
    email: "mailbyanil@gmail.com",
  },
  {
    id: 14,
    companyName: "Brighter World Engineering Works pro. Sonal Raverk",
    contactPerson: "Ashish Raverkar",
    phone: "9545512775",
    email: "bwew.corp@gmail.com",
  },
  {
    id: 15,
    companyName: "DILIGENT INNOVATIONS LLP",
    contactPerson: "DIVYANSHU GUPTA",
    phone: "8602080646",
    email: "diligentinnovationsindia@gmail.com",
  },
  {
    id: 16,
    companyName: "ELECTRICITYNOPROBLEM Pro Ajay Porwal",
    contactPerson: "Ajay Porwal",
    phone: "9826971771",
    email: "electricitynoproblem@gmail.com",
  },
  {
    id: 17,
    companyName: "ENERGY SYSTEM CONSULTANTS",
    contactPerson: "RAKESH NATH NAGAR",
    phone: "9425961391",
    email: "escindore@gmail.com",
  },
  {
    id: 18,
    companyName: "ESKAY TYRECRAFT",
    contactPerson: "KIRAN KUCHERIA",
    phone: "9826057800",
    email: "solareskay@yahoo.com",
  },
  {
    id: 19,
    companyName: "EXCLUSIVE MARKETING",
    contactPerson: "RITWIK TELANG",
    phone: "9302101837",
    email: "exclusivemarketing@gmail.com",
  },
  {
    id: 20,
    companyName: "FRELIT ENERGY PRIVATE LIMITED",
    contactPerson: "ANKUR KARKAR",
    phone: "9998622330",
    email: "frelitenergy.mp@gmail.com",
  },
  {
    id: 21,
    companyName: "FREYR ENERGY SERVICES PRIVATE LIMITED",
    contactPerson: "RADHIKA CHOUDARY",
    phone: "7331193388",
    email: "service@freyrenergy.com",
  },
  {
    id: 22,
    companyName: "Ganit Star Engineering",
    contactPerson: "Amit Goyal",
    phone: "9713346991",
    email: "ganitstar@gmail.com",
  },
  {
    id: 23,
    companyName: "GARGEE ENERGIES Pro Divyam Shah",
    contactPerson: "Divyam Shah",
    phone: "8857087103",
    email: "gargeeenergies@waareepartners.com",
  },
  {
    id: 24,
    companyName: "GLOBAL SOLAR ENERGY SOLUTIONS Pro Ashwin Patidar",
    contactPerson: "Ashwin Patidar",
    phone: "9754318151",
    email: "globalsolar047@gmail.com",
  },
  {
    id: 25,
    companyName: "GREEN ENERGY SYSTEMS Pro Gyanprakash rajabhau Godhane",
    contactPerson: "Gyanprakash rajabhau Godhane",
    phone: "8109844371",
    email: "greene.systems@gmail.com",
  },
  {
    id: 26,
    companyName: "HASTI COMPUTERS PRIVATE LIMITED",
    contactPerson: "RISHABH BETALA",
    phone: "7828389444",
    email: "rishabh.betala@hasticomputers.com",
  },
  {
    id: 27,
    companyName: "HEADSTREAM ENGINEERING PRIVATE LIMITED OPC",
    contactPerson: "BASANT BADJATYA",
    phone: "7747887766",
    email: "basantbadjatya@gmail.com",
  },
  {
    id: 28,
    companyName: "HI TECH ENTERPRISES Pro Narendra Rathore",
    contactPerson: "Narendra Rathore",
    phone: "9425052704",
    email: "HITECHSS18@Gmail.com",
  },
  {
    id: 29,
    companyName: "HITA ENERGY PRIVATE LIIMITED",
    contactPerson: "Hitendra Trivedi",
    phone: "7648810488",
    email: "director@hitaenergy.com",
  },
  {
    id: 30,
    companyName: "HPS HYDRO CONSULTANTS PRIVATE LIMITED",
    contactPerson: "Amit Kumar",
    phone: "8269488871",
    email: "hpsenergy4@gmail.com",
  },
  {
    id: 31,
    companyName: "INDIAN SOLAR SOLUTION",
    contactPerson: "Vinay Giri Goswami",
    phone: "9479785747",
    email: "indiansolarsolution@gmail.com",
  },
  {
    id: 32,
    companyName: "INDORE SUNLIGHT PRIVATE LIMITED",
    contactPerson: "Mayank Silawat",
    phone: "9111593999",
    email: "indoresunlight@gmail.com",
  },
  {
    id: 33,
    companyName: "INFINIT ENERGY SOLUTIONS PRIVATE LIMITED",
    contactPerson: "Pooja Tallera",
    phone: "9479831459",
    email: "projects@infinitenergy.in",
  },
  {
    id: 34,
    companyName: "INNOVATIVE ENERGY SOLUTIONS Pro Tanushri Jain",
    contactPerson: "Jaikish Jain",
    phone: "9827212397",
    email: "jaikish.jain47@gmail.com",
  },
  {
    id: 35,
    companyName: "JINESH VENTURES LLP",
    contactPerson: "MUKESH JAIN",
    phone: "7049810417",
    email: "jineshventuresllp@gmail.com",
  },
  {
    id: 36,
    companyName: "KSHAMA GAUR pro kshama gaur",
    contactPerson: "PAWAN GAUR",
    phone: "9893111151",
    email: "kshama.gour@yahoo.com",
  },
  {
    id: 37,
    companyName: "L M SOLAR SOLUTIONS",
    contactPerson: "TANUSH JAIN",
    phone: "9753500011",
    email: "LMSOLARSOLUTIONS@GMAIL.COM",
  },
  {
    id: 38,
    companyName: "MALWA SOLAR ENERGY Pro Mohammed Juned Multani",
    contactPerson: "Mohammed Juned Multani",
    phone: "8223000822",
    email: "info@malwasolarenergy.com",
  },
  {
    id: 39,
    companyName: "MN ENTERPRISES Pro Yash Thakkar",
    contactPerson: "YASH THAKKAR",
    phone: "8878887892",
    email: "mnenterprisesindore@gmail.com",
  },
  {
    id: 40,
    companyName: "MSKVA ECO ENERGY Pro Abhishek Pare",
    contactPerson: "Abhishek Pare",
    phone: "8109007361",
    email: "mskvaecoenergy@rediffmail.com",
  },
  {
    id: 41,
    companyName: "NARMADA TECHNOLOGIES Pro Sarika Chandwada",
    contactPerson: "Jitendra Chandwada",
    phone: "9302059439",
    email: "narmadatechnologies21@gmail.com",
  },
  {
    id: 42,
    companyName: "NEWSOL PV POWER PRIVATE LIMITED",
    contactPerson: "Raushan Kumar",
    phone: "7210929491",
    email: "npvservicesmp@gmail.com",
  },
  {
    id: 43,
    companyName: "NK POWER Pro Namrata Chaurasia",
    contactPerson: "Namrata Chaurasia",
    phone: "7987577396",
    email: "nkpower.ind@gmail.com",
  },
  {
    id: 44,
    companyName: "PARWATI ENTERPRISES Pro Vijay Kumar Malviya",
    contactPerson: "Vijay Kumar Malviya",
    phone: "9425072192",
    email: "parwati_enterprises@gmail.com",
  },
  {
    id: 45,
    companyName: "PATWALA'S HOME SOLUTION",
    contactPerson: "PRADEEP KUMAR",
    phone: "9329731616",
    email: "patwalashomesolution2020@gmail.com",
  },
  {
    id: 46,
    companyName: "PEOPLES ENGINEERING AND INFRA PRO KULDEEP VERMA",
    contactPerson: "KULDEEP VERMA",
    phone: "8770703854",
    email: "peoples.engineeringandinfra@gmail.com",
  },
  {
    id: 47,
    companyName: "PICKRENEW ENERGY PRIVATE LIMITED",
    contactPerson: "GOURAV BAPNA",
    phone: "7898663839",
    email: "info@pickrenew.com",
  },
  {
    id: 48,
    companyName: "Purvansh Electrical Trading and Training pro pooja",
    contactPerson: "Pooja Patel",
    phone: "6268284606",
    email: "purvanshelectrical@gmail.com",
  },
  {
    id: 49,
    companyName: "RATHI SOLAR COMPANY Pro Palash Rathi",
    contactPerson: "Palash Rathi",
    phone: "7977168635",
    email: "rathisolar@gmail.com",
  },
  {
    id: 50,
    companyName: "Rawat Industries Pro. Sumeet Rawat",
    contactPerson: "Sumeet Rawat",
    phone: "9993499999",
    email: "urjashop@gmail.com",
  },
  {
    id: 51,
    companyName: "ROBOSOL Pro Nilesh Kumar Shah",
    contactPerson: "NILESH KUMAR SHAH",
    phone: "8319010533",
    email: "robosolind@gmail.com",
  },
  {
    id: 52,
    companyName: "SAI SOLAR PRO RAJENDRA PAGARE",
    contactPerson: "RAJENDRA PAGARE",
    phone: "9827223143",
    email: "saisolarbarwani@gmail.com",
  },
  {
    id: 53,
    companyName: "SALSA ENERGY SOLUTIONS Pro Naved Ali",
    contactPerson: "NAVED ALI",
    phone: "9893178696",
    email: "navedali7575@gmail.com",
  },
  {
    id: 54,
    companyName: "SHAKTI ENERGY SOLUTIONS PRIVATE LIMITED",
    contactPerson: "Sandeep Yadav",
    phone: "7024130764",
    email: "tenders@shaktienergysolution.com",
  },
  {
    id: 55,
    companyName: "SHANTI ELECTRICALS SYSTEM Pro Prashant Bansal",
    contactPerson: "Prashant Bansal",
    phone: "9827304292",
    email: "shantitechno@yahoo.com",
  },
  {
    id: 56,
    companyName: "SHIKHAR SOLAR",
    contactPerson: "DIPALI KANUNGO",
    phone: "9340965469",
    email: "shikharsolarindore@gmail.com",
  },
  {
    id: 57,
    companyName: "SHREYA ELECTRICALS Pro Dhawal Sethi",
    contactPerson: "Dhawal Sethi",
    phone: "7999099263",
    email: "dhawalsethi20@gmail.com",
  },
  {
    id: 58,
    companyName: "SHREYANS ENERGY PVT LTD",
    contactPerson: "TARUN SONI",
    phone: "9300126830",
    email: "info@shreyansenergy.com",
  },
  {
    id: 59,
    companyName: "SILRES ENERGY SOLUTIONS PRIVATE LIMITED",
    contactPerson: "Sreekar Bhuvakar",
    phone: "9789811899",
    email: "silres@feniceenergy.com",
  },
  {
    id: 60,
    companyName: "SJ POWER CO. Prop. Shibu Baby",
    contactPerson: "Shibu Poovannalil",
    phone: "9630020077",
    email: "sjpowerco@gmail.com",
  },
  {
    id: 61,
    companyName: "SOLARKIA ENERGY PRIVATE LIMITED",
    contactPerson: "KUSH PARMAR",
    phone: "9893508860",
    email: "info@solarkia.in",
  },
  {
    id: 62,
    companyName: "SOLARMATE ENERGY PRIVATE LIMITED",
    contactPerson: "KUSH PARMAR",
    phone: "9893508860",
    email: "parmarkush1@gmail.com",
  },
  {
    id: 63,
    companyName: "SOLARSQUARE ENERGY PRIVATE LIMITED",
    contactPerson: "Manoj Kumar",
    phone: "8826376150",
    email: "manoj.k@solarsquare.in",
  },
  {
    id: 64,
    companyName: "SUNGRACIA SALES CORPORATION Pro Piyush Goyal",
    contactPerson: "PIYUSH GOYAL",
    phone: "9926677570",
    email: "piyushsofpour@gmail.com",
  },
  {
    id: 65,
    companyName: "SUNVIBES ENTERPRISES PRIVATE LIMITED",
    contactPerson: "PALKESH VERMA",
    phone: "7375055555",
    email: "palkeshverma639@gmail.com",
  },
  {
    id: 66,
    companyName: "SURAYAY NAMAH ENERGY PRIVATE LIMITED",
    contactPerson: "Rahul Singraha",
    phone: "8770611660",
    email: "rahul.singraha@surayaynamahenergy.com",
  },
  {
    id: 67,
    companyName: "SUYASH TRADEX LLP",
    contactPerson: "RONIT GARG",
    phone: "9826888896",
    email: "suyashtradex@gmail.com",
  },
  {
    id: 68,
    companyName: "Trade Plus Resources and Solutions Pvt Ltd",
    contactPerson: "C P Toshniwal",
    phone: "9131322259",
    email: "tprspl@gmail.com",
  },
  {
    id: 69,
    companyName: "TRIANGLE ENTERPRISES Pro Kuldeep Prajapat",
    contactPerson: "Kuldeep Prajapat",
    phone: "9977667330",
    email: "t.triangleenterprises@gmail.com",
  },
  {
    id: 70,
    companyName: "TRUETECH ENERGY PRIVATE LIMITED",
    contactPerson: "SANJAY KUMAR SOLANKI",
    phone: "9617271891",
    email: "sanjay.solanki91@gmail.com",
  },
  {
    id: 71,
    companyName: "VIBRANT ENERGY SOLUTIONS Pro Raj Kamal Saraf",
    contactPerson: "Raj Kamal Saraf",
    phone: "9820860111",
    email: "raj@vibrantenergysolutions.in",
  },
  {
    id: 72,
    companyName: "XPANZ ENERGY SOLUTIONS LLP",
    contactPerson: "Aakash Malik",
    phone: "9873326193",
    email: "xpanzsolar@gmail.com",
  },
];
export default VendorList;
