import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ref, get, child, update } from "firebase/database";
import { db } from "../../../../../firebase";
import AuthContext from "../../../../../Context/AuthContext";
import profile from "../../../../../Assests/Vendor/profile.png";
import notificationVendor from "../../../../../Assests/Vendor/notificationVendor.png";
import product from "../../../../../Assests/Vendor/product.png";
import userEng from "../../../../../Assests/Vendor/userEng.png";
import person from "../../../../../Assests/Vendor/help.png";

const VendorUpdate = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState("profile");
  const currentUser = localStorage.getItem("currentUser");
  const AuthCtx = useContext(AuthContext);
  const imageUrl = AuthCtx.vendorProfileImage;
  const navigate = useNavigate();
  const params = useParams();
 
  const [vendorFormData, setVendorFormData] = useState({
    contractorId: "",
    vendorName: "",
    address: "",
    email: "",
    mobile: "",
    open: "",
    closing: "",
  });

  const updatedFormData = {
    contractorId: vendorFormData.contractorId,
    vendorName: vendorFormData.vendorName,
    address: vendorFormData.address,
    email: vendorFormData.email,
    mobile: vendorFormData.mobile,
    open: vendorFormData.open,
    closing: vendorFormData.closing,
  };

  useEffect(() => {
    const fetchVendor = async (currentUser) => {
      try {
        const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
        const dataRef = ref(db, `vendor/${emailPath}`);
        const snapshot = await get(child(dataRef, "/"));

        if (snapshot.exists()) {
          const vendorData = snapshot.val();
          const data = Object.values(vendorData)[0];
          setVendorFormData({
            contractorId: data.contractorId || "",
            vendorName: data.vendorName || "",
            address: data.address || "",
            email: data.email || "",
            mobile: data.mobile || "",
            open: data.open || "",
            closing: data.closing || "",
          });
          AuthCtx.fetchVendorImages(`vendor/${emailPath}/profile`);
        } else {
          console.log("No data found for the entered email.");
        }
      } catch (error) {
        console.log("Error fetching vendor data;", error);
      }
    };

    fetchVendor(currentUser);
  }, []);

  const handleUpdatedChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setVendorFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateDataForVendor = async (e) => {
    e.preventDefault();
    console.log("submitted");
    try {
      if (!currentUser || !params.id) {
        console.error("currentUser or params.id is undefined.");
        return;
      }
  
      const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
      const userId = params.id;
  
      // Check if emailPath or userId is undefined
      if (!emailPath || !userId) {
        console.error("emailPath or userId is undefined.");
        return;
      }

      console.log("Id=>",userId)
  
      const dataRef = ref(db, `vendor/${emailPath}/${userId}`);

      
      await update(dataRef, updatedFormData);

      console.log(`Data under ID ${params.id} updated successfully.`);
      navigate("/vendorprofile");
    } catch (error) {
      console.error(`Error updating data: ${error}`);
    }
  };
  
  const handleImageUpload = async (e) => {
    const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
    const file = e.target.files[0];
    const deletedataRef = `vendor/${emailPath}/profile`;
    const uploadDataRef = `vendor/${emailPath}/profile/${file.name}`;
    await AuthCtx.uploadImage(file, deletedataRef, uploadDataRef);
    await AuthCtx.fetchVendorImages(`vendor/${emailPath}`);
  };

  return (
    <div className="main flex">
      <div className="block lg:hidden absolute z-50 ml-5 mt-3">
        <button onClick={() => setShowSidebar(!showSidebar)}>
          <i className="fas fa-bars fa-xl"></i>
        </button>
      </div>
      <div
        className={`sidebar s:absolute lg:relative top-0 left-0 ${
          showSidebar ? "block" : "hidden"
        } lg:block s:w-[50%] s:h-[90%] md:w-[25%] md:h-[100vh]  z-40  s:bg-white lg:bg-white h-screen s:break-words lg:w-[20%] xl2:w-[17%]`}
      >
        <ul className="flex flex-col gap-y-5 pt-28 pl-6 ">
          <Link to="/vendorprofile" onClick={() => setActiveLink("profile")}>
            <li
              className={`flex items-center gap-x-2 ${
                activeLink === "profile" ? "bg-[#235688] text-white" : ""
              }`}
            >
              <img src={profile} alt="" className="w-7 text-white" />
              Profile
            </li>
          </Link>

          <li className="flex items-center gap-x-2">
            <img src={notificationVendor} alt="" className="w-7" />
            Notification
          </li>
          <Link to = "/vendorproductlist">
          <li className="flex items-center gap-x-2">
            <img src={product} alt="" className="w-7" />
            Product
          </li>
          </Link>
          <li className="flex items-center gap-x-2 break-words ">
            <img src={userEng} alt="" className="w-7" />
            User Engagement
          </li>
        </ul>
      </div>

      <div className="content h-full s:w-[100%] lg:w-[80%] xl2:w-[83%]">
        <div className="navbar md:w-[100%] h-[15%] s:h-14 md:h-[15%] bg-white flex justify-end items items-center gap-x-5">
          <img
            className="md:w-10 md:h-12 s:w-12 s:h-10 md:block rounded-full"
            src={imageUrl ? imageUrl : person}
            alt=""
          />
          <span className=" md:block s:text-1xl md:text-sm">
            {vendorFormData.vendorName}
          </span>
          <div className="bg-gray-400 md:w-8 md:h-8 s:w-6 s:h-6 rounded-full mr-14 md:block">
            <span className="ml-3 font-bold s:text-1 xl md:text-sm s:ml-2 md:ml-3">
              ?
            </span>
          </div>
        </div>

        <div className="middle-content bg-blue-100 w-full h-[100%] md:p-[30px] s:p-[20px]">
          <div className="form bg-white p-6 flex s:flex-col md:flex-row md:ml-0 md:mr-0 m:ml-8 m:mr-8">
            <div className="first-column sm:ml-6 md:-ml-8 xl2:ml-8">
              <div className="">
                <p className="font-bold text-2xl md:mt-10 md:ml-10 text-blue-900">
                  Profile
                </p>
              </div>

              <div
                className="relative s:w-40 s:h-40 rounded-full overflow-hidden border border-orange-500 bg-[#D9D9D9]
                          md:ml-10 s:ml-8 md:mt-8 s:mt-6 cursor-pointer"
              >
                <label htmlFor="imageInput">
                  <img
                    className="object-cover w-full h-full"
                    src={imageUrl ? imageUrl : person}
                    alt=""
                    loading="lazy"
                  />
                </label>
                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>

              <div className="relative overflow-hidden w-6 h-6 s:ml-40 lg:ml-[165px] rounded-full bg-orange-500 mt-[-40px]">
                <p className="text-2xl text-white pl-[4px]  mt-[-7px]">+</p>
              </div>
            </div>

            <div className="second-column md:mt-16 md:ml-7 sm:ml-8 xl2:ml-14">
              <p className="hidden md:block">
                <i className="fa-solid fa-user"></i>&emsp; Profile
              </p>
              <div className="mt-20">
                <p className="text-xl font-semibold">Shop Name</p>
                <input
                  className="md:w-[200px] s:w-[220px] xl2:w-[300px] text-[#00000066] bg-[#D9D9D9] p-2 rounded mt-2"
                  type="text"
                  name="vendorName"
                  value={updatedFormData.vendorName}
                  placeholder="HPS Hydro Consultants Pvt. Ltd."
                  maxLength={50}
                  onChange={handleUpdatedChange}
                />
              </div>
              <div className="mt-6">
                <p className="text-xl font-semibold">Contractor ID</p>
                <input
                  className="md:w-[200px] s:w-[220px] xl2:w-[300px] text-[#00000066] bg-[#D9D9D9] p-2 rounded mt-2"
                  type="text"
                  name="contractorId"
                  disabled
                  value={updatedFormData.contractorId}
                  placeholder="RFTPCTR146"
                  maxLength={50}
                  onChange={handleUpdatedChange}
                />
              </div>
              <div className="mt-6">
                <p className="text-xl font-semibold">Phone No.</p>
                <input
                  className="md:w-[200px] s:w-[220px] xl2:w-[300px] text-[#00000066] bg-[#D9D9D9] p-2 rounded mt-2"
                  type="text"
                  name="mobile"
                  value={updatedFormData.mobile}
                  placeholder="HPS Hydro Consultants Pvt. Ltd."
                  maxLength={12}
                  onChange={handleUpdatedChange}
                />
              </div>
              <div className="md:ml-8 s:ml-10 xl2:ml-20">
                <button
                  className="w-[140px] text-[#00000066] bg-[#D9D9D9] md:mt-14 s:mt-8 h-10"
                  placeholder="Clear Details"
                  type="clear"
                  name="cleardetail"
                >
                  Clear Details
                </button> 
                </div>
            </div>

            <div className="third-column md:mt-[166px] s:mt-10 md:ml-14 lg:ml-16 xl2:ml-24">
              <div>
                <p className="text-xl font-semibold">Shop Timing</p>
                <div className="flex mt-2">
                  <div className="">
                    <p className="text-lg font-medium">Open</p>
                    <input
                      type="text"
                      className="s:ml-12 md:ml-1 mt-2 md:w-[90px] xl2:w-[120px] s:w-[90px] p-1 text-[#00000066] bg-[#D9D9D9]"
                      name="open"
                      placeholder="00 : 00"
                      maxLength={50}
                      value={updatedFormData.open}
                      onChange={handleUpdatedChange}
                    />
                    {/* <p className="mt-2 md:w-[90px] s:w-[90px] xl2:w-[120px] p-1 text-[#00000066] bg-[#D9D9D9]">
                      9:15 AM
                      
                    </p> */}
                  </div>
                  <div>
                    <p className="text-lg font-medium s:ml-12 md:ml-6">
                      Closing
                    </p>
                    <input
                      type="text"
                      className="s:ml-12 md:ml-6 mt-2 md:w-[90px] xl2:w-[120px] s:w-[90px] p-1 text-[#00000066] bg-[#D9D9D9]"
                      name="closing"
                      placeholder="00 : 00"
                      maxLength={50}
                      value={updatedFormData.closing}
                      onChange={handleUpdatedChange}
                    />
                    {/* <p className="s:ml-12 md:ml-6 mt-2 md:w-[90px] xl2:w-[120px] s:w-[90px] p-1 text-[#00000066] bg-[#D9D9D9]">
                      5:45 PM
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <p className="text-xl font-semibold">Email</p>
                <input
                  className="bg-[#D9D9D9] md:w-[200px] xl2:w-[300px] s:w-[220px] text-[#00000066] p-2 mt-2"
                  type="email"
                  name="email"
                  disabled
                  value={updatedFormData.email}
                  placeholder="yogendra.coderform@gmail.com"
                  maxLength={50}
                  onChange={handleUpdatedChange}
                />
              </div>

              <div className="mt-6">
                <p className="text-xl font-semibold">Address</p>
                <input
                  className="bg-[#D9D9D9] md:w-[200px] xl2:w-[300px] s:w-[220px] text-[#00000066] p-2 mt-2"
                  type=""
                  name="address"
                  value={updatedFormData.address}
                  placeholder="OR-264, Indus Satellite Greens Kalod Hala A. B. Road"
                  maxLength={100}
                  onChange={handleUpdatedChange}
                />
              </div>

              <div className="mt-7 md:ml-10 s:ml-10 xl2:ml-20">
                <button
                  className="w-[140px] text-white bg-[#235688] h-10"
                  onClick={updateDataForVendor}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorUpdate;
