import React from "react";
import styles from "./AboutSchemes.module.css";
import citizen from "../../../Assests/AboutSchemesLogos/citizen.png";
import vendor from "../../../Assests/AboutSchemesLogos/vendor.png";
import notification from "../../../Assests/AboutSchemesLogos/notification.png";
import qrrCode from "./qrrCode.jpeg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AboutSchemes = () => {
  const { t, i18n } = useTranslation("translation");
  const navigate = useNavigate();

  const installedSolar = () => {
    window.open("https://www.pmsuryaghar.gov.in/consumerLogin", "_blank");
  };

  const navigateHandler = () => {
    navigate("/resolutionform");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* <div className={styles.mainTitle}>
          <h1>About Schemes</h1>
        </div> */}
        <div className={styles.cards}>
          <div className={styles.card}>
            <img src={citizen} alt="" />
            <h2>{t("CARD1")}</h2>
            <p>
              <div className="flex justify-center align-bottom">
                <button
                  className=" border-2 border-black hover:border-white  rounded-lg p-4 md:mt-12 sm:mt-6 m:mt-5 s:mt-5 font-semibold  "
                  onClick={() => {
                    installedSolar();
                  }}
                >
                  Apply Here
                </button>
              </div>
            </p>
          </div>
          <div className={styles.card}>
            <img src={vendor} alt="" />
            <h2>{t("CARD2")}</h2>
            <div onClick={navigateHandler}>
              <img src={qrrCode} alt="" className={`${styles.qrCode}`} />
            </div>
          </div>

          <div className={`${styles.card} ${styles["notification-card"]}`}>
            <div className={styles["notification-title"]}>
              <img src={notification} alt="" />
              <h2>{t("CARD3")}</h2>
            </div>
            <div className={styles["notification-content"]}>
              <p>
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here! Updates on
                Solar will appear here! Updates on Solar will appear here!
                Updates on Solar will appear here! Updates on Solar will appear
                here! Updates on Solar will appear here! Updates on Solar will
                appear here! Updates on Solar will appear here! Updates on Solar
                will appear here! Updates on Solar will appear here!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSchemes;
