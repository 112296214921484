import React, { useContext, useRef } from "react";
import Close from "../Assests/dashboardAssets/close.png";
import Certificate from "./Images/certificate.png";
import cloud from "../Assests/Vendor/cloud.png";
import AuthContext from "../Context/AuthContext";
import { ref } from "firebase/storage";
import { db } from "../firebase";
import { push } from "firebase/database";

const GreenCertiRequest = () => {
  const AuthCtx = useContext(AuthContext);
  const fileInputRef = useRef(null);
  const greenCertificateForm = AuthCtx.GreenCertificateForm;
  const userEmail = "xty@gmail.com";
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);

    const updatedData = {
      ...greenCertificateForm,
      [name]: value,
    };
    // console.log(updatedData);
    AuthCtx.updateGreenCertificateForm(updatedData);
  };

  const handleDatabaseStorage = () => {
    console.log(greenCertificateForm.email);
    const email = greenCertificateForm.email.split("@")[0];
    const emailPath = email.replace(/[.#$/[\]]/g, "_");
    // console.log(emailPath);
    const dataRef = ref(db, `greencertificate/${emailPath}`);
    console.log("dataref ", dataRef);

    push(dataRef, greenCertificateForm)
      .then(() => {
        console.log("User data stored in the database.");
      })
      .catch((error) => {
        console.error("Error storing user data:", error);
      });
  };

  const handleFileSelect = (e) => {
    const files = e.target.files;
    console.log(files)
    if (files.length > 0) {
      const file = files[0];
      AuthCtx.uploadDocumentToStorage(file)
        .then((downloadURL) => {
          const updatedData = {
            ...greenCertificateForm,
            document: downloadURL,
          };
          AuthCtx.updateVendorVerificationData(updatedData);
        })
        .catch((error) => {
          console.error("Error uploading or getting download URL:", error);
        });
    }
  };

  const openFileSelect = () => {
    fileInputRef.current.click();
  };




  const handleSubmit = () => {
    // console.log("greenCertificateForm:", greenCertificateForm);
    handleDatabaseStorage(greenCertificateForm);
  };
  return (
    <div className="bg-[#8A8F93] w-full h-[100%] md:px-16 s:px-7">
      <div className="Close-Icon">
        <img
          src={Close}
          alt=""
          className="md:w-[4%] s:w-[8%] ml-[100%] cursor-pointer"
        />
      </div>
      <div className="bg-white w-full p-3 md:p-10 flex md:flex-row s:flex-col -mt-12">
        <div className="Image-section md:w-[50%]  md:ml-8">
          <p className="text-[#152C5B] font-semibold mb-4 mt-10 md:mt-0">
            Green Certificate Request Form
          </p>
          <img
            src={Certificate}
            alt=""
            className="md:w-[60%] s:w-[100%] mb-4"
          />
          <div className="mb-5 relative">
            <label className="block text-sm font-semibold mb-2">
              Pan Card Number
            </label>
            <input
              type="text"
              id="pan"
              name="pancard"
              placeholder="Enter Pan Card Number"
              value={greenCertificateForm.pancard}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <div className="md:mb-10 mb-5">
            <label className="block text-sm font-semibold mb-2">
              Aadhar Card Number
            </label>
            <input
              type="text"
              id="aadgar"
              name="aadhar"
              placeholder="Enter Aadhar Card Number"
              value={greenCertificateForm.aadhar}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <p className="text-[#152C5B] font-bold hidden md:block md:mb-10 mb-5">
            Documents Uploding{" "}
          </p>
        </div>
        <div className="Input-Section md:w-[50%] ">
          <div className="mb-5">
            <label className="block text-sm font-semibold mb-2">
              House Owner Name
            </label>
            <input
              type="text"
              id="house"
              name="house"
              placeholder="Enter House Owner Name"
              value={greenCertificateForm.house}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <div className="mb-5">
            <label className="block text-sm font-semibold mb-2">
              Registered Phon No.
            </label>
            <input
              type="text"
              id="regis"
              name="regis"
              placeholder="Enter Phone No."
              value={greenCertificateForm.regis}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <div className="mb-5">
            <label className="block text-sm font-semibold mb-2">
              solar panel unique REF number
            </label>
            <input
              type="text"
              id="solar"
              name="solar"
              placeholder="Enter solar panel unique ref number"
              value={greenCertificateForm.solar}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <div className="mb-5">
            <label className="block text-sm font-semibold mb-2">
              Registered E-Mail
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Enter Registered E-Mail"
              value={greenCertificateForm.email}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <div className="md:mb-28 s:mb-12">
            <label className="block text-sm font-semibold mb-2">
              Registration Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Enter Registration Address"
              value={greenCertificateForm.address}
              onChange={handleChange}
              className="border rounded p-2 w-full md:w-[80%] bg-[#C3D1DC40]"
              required
            />
          </div>
          <p className="text-[#152C5B] font-bold md:hidden text-center">
            Documents Uploding{" "}
          </p>
        </div>
      </div>
      <div className="bg-white w-full p-3 md:p-10 flex md:flex-row s:flex-col lg:-mt-24 md:-mt-36">
        <div className="First md:w-[60%] md:ml-8">
          <div className="Electricity-Bill flex flex-col space-y-1 md:mb-10 mb-5">
            <label className="block text-sm font-semibold">
              Attach Electricity Bill
            </label>
            <div  onClick={openFileSelect} className=" bg-blue-50 flex flex-col items-center justify-center md:w-[80%] p-[7%] border-2 border-dashed border-gray-400 rounded">
              <img src={cloud} alt="" className="w-[8%]" />
                <input 
                  type="file"
                  ref={fileInputRef}
                  name="document"
                  id="document"
                  accept=".doc, .pdf, .png, .jpg"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
              <p className="text-gray-500">Drag and Drop here</p>
            </div>
            <p className="text-gray-500 text-sm">
              Accept File Types : doc,pdf,png,jpg
            </p>
            
          </div>



          <div className="Photo-solar flex flex-col space-y-1 s:mb-5 md:mb-0">
            <label className="block text-sm font-semibold">
              Photo With Solar Panel
            </label>
            <div onClick={openFileSelect} className=" bg-blue-50 flex flex-col items-center justify-center md:w-[80%] p-[7%] border-2 border-dashed border-gray-400 rounded">
              <img src={cloud} alt="" className="w-[8%]" />
              <input 
                  type="file"
                  ref={fileInputRef}
                  name="document"
                  id="document"
                  accept=".doc, .pdf, .png, .jpg"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
              <p className="text-gray-500">Drag and Drop here</p>
            </div>
            <p className="text-gray-500 text-sm">
              Accept File Types : doc,pdf,png,jpg
            </p>
          </div>
        </div>
        <div className="second md:w-[60%]">
          <div className="flex flex-col space-y-1 md:mb-16 mb-5">
            <label className="block text-sm font-semibold">
              Bill Issued By Vendor
            </label>
            <div  onClick={openFileSelect} className=" bg-blue-50 flex flex-col items-center justify-center md:w-[80%] p-[7%] border-2 border-dashed border-gray-400 rounded">
              <img src={cloud} alt="" className="w-[8%]" />
              <input 
                  type="file"
                  ref={fileInputRef}
                  name="document"
                  id="document"
                  accept=".doc, .pdf, .png, .jpg"
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
              <p className="text-gray-500">Drag and Drop here</p>
            </div>
            <p className="text-gray-500 text-sm">
              Accept File Types : doc,pdf,png,jpg
            </p>
          </div>
          <div className="flex flex-col space-y-1 md:mb-10 mb-5 cursor-pointer">
            <div className=" bg-blue-900 flex flex-col items-center justify-center md:w-[80%] s:p-[6%] md:p-[5%] lg:p-[8%] rounded lg:mt-3 md:mt-0.5">
              <p className="text-white font-semibold" onClick={handleSubmit}>
                Apply For Green Verification
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreenCertiRequest;
