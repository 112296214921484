import React, { useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import bg from "./sankalpPatra.jpg";
import "./ResolutionPdf.css";

const ResolutionPdf = () => {
  const pdfRef = useRef();
  const AuthCtx = useContext(AuthContext);
  const resolutionForm = AuthCtx.resolutionForm;
  const [isPreviewMode, setPreviewMode] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const buttonStyle = {
    background: "linear-gradient(135deg, #1682b4, #1974a6, #205284)",
    color: "#fff",
    padding: "15px 20px",
    border: "none",
    borderRadius: "40px",
    fontWeight: "bold",
    cursor: "pointer",
    margin: "10px",
    minWidth: "150px",
    boxSizing: "border-box",
  };

  const loaderContainerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const loaderSpinnerStyle = {
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderTop: "4px solid #3498db",
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    animation: "spin 1s linear infinite",
  };

  const keyframesStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  const downloadPDF = async () => {
    try {
      setLoading(true);
      const input = pdfRef.current;
      const scale = 3;
      const canvas = await html2canvas(input, {
        useCORS: true,
        scale: scale,
      });
      const imgData = canvas.toDataURL("image/png", 0.8);
      const pdf = new jsPDF("p", "px", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = (pdfHeight - imgHeight * ratio) / 2;
      pdf.addImage(bg, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
        "FAST"
      );

      pdf.save(`Har Ghar Solar`);

      setPreviewMode(false);
      AuthCtx.setResolutionForm({
        Name: "",
        Mobile: "",
        Address: "",
        LightBill: "",
        ResolutionTime: "",
      });

      navigate("/resolutionform");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pdf-container">
      {isPreviewMode && (
        <div ref={pdfRef} className="pdf-preview">
          <img src={bg} alt="Background" className="pdf-background" />
          <div className="pdf-content">
            <p className="pdf-text">
              मैं {resolutionForm.Name} संकल्प <br></br>
              लेता/लेती हूँ की इन्दौर शहर के महापौर श्री पुष्यमित्र भार्गव
              द्वारा चलाये जा रहे सौर मित्र अभियान में सहभागिता करते हुए तीन माह
              के भीतर अपने घर/प्रतिष्ठान में सौर संयंत्र स्थापित करते हुए शहर को
              सोलर सिटी बनाने में अपना योगदान दूंगा/दूंगी |
            </p>
            <br></br>
            <p className="pdf-details">
              <span className="user-info">नाम : </span>
              {resolutionForm.Name}
              <br></br>
              <span className="user-info">संपर्क नम्बर : </span>
              {resolutionForm.Mobile}
              <br></br>
              <span className="user-info">दिनांक : </span>
              {resolutionForm.date}
              <br></br>
              <span className="user-info">संकल्प ID : </span>
              {AuthCtx.resolutionForm.SankalpId}
              <br></br>
            </p>
          </div>
        </div>
      )}
      <style>{keyframesStyle}</style>

      {isLoading && (
        <div style={loaderContainerStyle}>
          <div style={loaderSpinnerStyle}></div>
        </div>
      )}
      <div style={{ margin: "20px" }}>
        <button style={buttonStyle} className="" onClick={downloadPDF}>
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default ResolutionPdf;
