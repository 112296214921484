import { createContext, useState } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
} from "firebase/auth";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject,
  list,
} from "firebase/storage";
import { app, db, storage } from "../firebase";
import { useNavigate } from "react-router-dom";
import { child, get, push, update } from "firebase/database";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const currentDate = new Date().toLocaleDateString("en-US", options);
  const [user, setUser] = useState(null);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isContractor, setIsContractor] = useState(false);
  const [productImages, setProductImages] = useState("");
  const [userType, setUserType] = useState("");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const [currentLanguage, setCurrentLanguage] = useState(true);
  const [vendorProfileImage, setVendorProfileImage] = useState("");
  const [citizenFormData, setCitizenFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [vendorFormData, setVendorFormData] = useState({
    contractorId: "",
    vendorName: "",
    address: "",
    email: "",
    mobile: "",
    open: "",
    closing: "",
  });
  const [vendorVerificationData, setVendorVerificationData] = useState({
    name: "",
    email: "",
    phone: "",
    aadharNumber: "",
    gstNumber: "",
    panNumber: "",
    document: null,
    address: "",
  });
  const [vendorHelpFormData, setVendorHelpFormData] = useState({
    name: "",
    email: "",
    phone: "",
    document: null,
    subject: "",
    description: "",
    preferredContactMethod: [],
  });

  const [resolutionForm, setResolutionForm] = useState({
    Name: "",
    Mobile: "",
    Address: "",
    LightBill: "",
    date: currentDate,
    ResolutionTime: "3",
    SankalpId: "",
    RefferedBy: "",
  });

  const [GreenCertificateForm, setGreenCertificateForm] = useState({
    pancard: "",
    aadhar: "",
    billdocument: null,
    solardocument: null,
    house: "",
    regis: "",
    solar: "",
    email: "",
    address: "",
    issuedocument: null,
  });

  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("newuser");
    navigate("/");
  };

  const updateLanguage = (data) => {
    setCurrentLanguage(data);
  };

  //update CITIZEN
  const updateCitizenFormData = (data) => {
    setCitizenFormData(data);
  };

  //update VENDOR
  const updateVendorFormData = (data) => {
    setVendorFormData(data);
  };

  //update VENDOR VERIFICATION
  const updateVendorVerificationData = (data) => {
    setVendorVerificationData(data);
  };

  //update VENDOR HELP
  const updateVendorHelpFormData = (data) => {
    setVendorHelpFormData(data);
  };

  const updateGreenCertificateForm = (data) => {
    setGreenCertificateForm(data);
  };

  const updateMobileVerification = () => {
    setIsMobileVerified(true);
  };

  const updateEmailVerification = () => {
    setIsEmailVerified(true);
  };

  const updateUser = (newConfirmation) => {
    setUser(newConfirmation);
  };

  const updateOtp = (newOtp) => {
    setOtp(newOtp);
  };

  const setContractor = () => {
    console.log("Contractor => TRUE");
    setIsContractor(true);
  };

  const updateUserType = (type) => {
    setUserType(type);
  };

  //Phone Sign In
  const handlePhoneVerification = async () => {
    try {
      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
        callback: (response) => {
          console.log("reCAPTCHA verified:", response);
        },
      });
      const phoneNumber =
        userType === "CITIZEN"
          ? "+91" + citizenFormData.mobile
          : "+91" + vendorFormData.mobile;
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      setUser(confirmation);
      alert("OTP Sent Successfully...");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  // Google Sign In
  const handleGoogleSignIn = () => {
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setUser(user);
        console.log("Google Sign In Successful!");
        navigate("/citizendashboard");
      })
      .catch((error) => {
        console.error("Google sign-in error:", error);
      });
  };

  // Facebook Sign In
  const handleFacebookSignIn = () => {
    const auth = getAuth(app);
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        setUser(user);
        console.log("Facebook Sign In Successful!");
      })
      .catch((error) => {
        console.error("Facebook sign-in error:", error);
      });
  };

  const uploadDocumentToStorage = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, "files/" + file.name);

      // Upload the selected file to Firebase Storage
      uploadBytes(storageRef, file)
        .then((snapshot) => {
          // Get the download URL of the uploaded file
          getDownloadURL(snapshot.ref)
            .then((downloadURL) => {
              resolve(downloadURL); // Resolve the URL when the upload is successful
            })
            .catch((error) => {
              reject(error); // Reject with an error if there's any issue with getting the URL
            });
        })
        .catch((error) => {
          reject(error); // Reject with an error if there's any issue with the upload
        });
    });
  };

  // Data fetching Function
  const fetchData = async (dataRef) => {
    try {
      const snapshot = await get(child(dataRef, "/"));
      console.log("fetched data => ", snapshot);

      if (snapshot.exists()) {
        const vendorData = snapshot.val();
        const data = Object.values(vendorData)[0];
        setVendorFormData({
          contractorId: data.contractorId || "",
          vendorName: data.vendorName || "",
          address: data.address || "",
          email: data.email || "",
          mobile: data.mobile || "",
          open: data.open || "",
          closing: data.closing || "",
        });
      } else {
        console.log("No data found for the entered email.");
      }
    } catch (error) {
      console.log("Error fetching vendor data;", error);
    }
  };

  const handleDatabaseStorage = (formData) => {
    const email = formData.email.split("@")[0];
    const emailPath = email.replace(/[.#$/[\]]/g, "_");
    const dataRef = formData.contractorId
      ? ref(db, `vendor/${emailPath}`)
      : ref(db, `citizen/${emailPath}`);

    push(dataRef, formData)
      .then(() => {
        console.log("User data stored in the database.");
      })
      .catch((error) => {
        console.error("Error storing user data:", error);
      });
  };

  const handleProductsDatabaseStorage = async (dataRef, productData) => {
    try {
      const storageRef = ref(storage, dataRef);

      const snapshot = await uploadBytes(storageRef, productData);
      if (snapshot) {
        console.log(
          "Image Uploaded... Click Fetch Button to See the uploaded images"
        );
      } else {
        alert("Error in upload image...");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // image uplaod function
  const uploadImage = async (file, deleteDataRef, uploadDataRef) => {
    try {
      const storageRef = ref(storage, deleteDataRef);
      const listResult = await list(storageRef);

      // Delete each item in the list
      await Promise.all(
        listResult.items.map(async (item) => {
          await deleteObject(item);
          console.log(`Deleted existing image: ${item.name}`);
        })
      );

      // Upload the new image
      console.log(uploadDataRef);
      const newImageRef = ref(storage, uploadDataRef);
      const snapshot = await uploadBytes(newImageRef, file);

      if (snapshot) {
        console.log("Image Uploaded Successfully...");
      } else {
        alert("Error in upload image...");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Delete Existing images
  const deleteExistingImages = async (storagePath) => {
    try {
      const storageRef = ref(storage, storagePath);
      const listResult = await list(storageRef);

      // Delete each item in the list
      await Promise.all(
        listResult.items.map(async (item) => {
          await deleteObject(item);
          console.log(`Deleted existing image: ${item.name}`);
        })
      );
    } catch (error) {
      console.error("Error deleting existing images:", error);
    }
  };

  //image fetching
  const fetchVendorImages = async (dataRef) => {
    try {
      // const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
      const storageRef = ref(storage, dataRef);
      const listResult = await listAll(storageRef);

      const urls = await Promise.all(
        listResult.items.map(async (item) => {
          const downloadURL = await getDownloadURL(item);
          return downloadURL;
        })
      );
      console.log(urls);
      setVendorProfileImage(urls);
    } catch (error) {
      console.error("Error fetching vendor images:", error);
    }
  };

  const fetchVendorImageByFilename = async (dataRef, filename) => {
    try {
      const storageRef = ref(storage, `${dataRef}/${filename}`);
      console.log(storageRef);
      const downloadURL = await getDownloadURL(storageRef);
      console.log(downloadURL);
      setProductImages(downloadURL);
      // Log or use the downloadURL as needed
      console.log(downloadURL);

      // Return the downloadURL or use it in your application
      return downloadURL;
    } catch (error) {
      console.error("Error fetching vendor image by filename:", error);
      // Handle the error as needed
    }
  };

  // console.log(userType ? userType : "NO USER");

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        otp,
        updateOtp,
        user,
        setUser,
        updateUser,
        userType,
        updateUserType,
        citizenFormData,
        updateCitizenFormData,
        vendorFormData,
        updateVendorFormData,
        vendorVerificationData,
        updateVendorVerificationData,
        vendorHelpFormData,
        updateVendorHelpFormData,
        isContractor,
        setContractor,
        isMobileVerified,
        updateMobileVerification,
        isEmailVerified,
        updateEmailVerification,
        handleGoogleSignIn,
        handleFacebookSignIn,
        handlePhoneVerification,
        uploadDocumentToStorage,
        uploadImage,
        fetchData,
        fetchVendorImages,
        vendorProfileImage,
        handleProductsDatabaseStorage,
        productImages,
        setProductImages,
        fetchVendorImageByFilename,
        deleteExistingImages,
        resolutionForm,
        setResolutionForm,
        updateLanguage,
        currentLanguage,
        GreenCertificateForm,
        updateGreenCertificateForm,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
