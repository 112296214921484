import React from "react";
import logo2 from "../../../Assests/NavLogos/logo2.png";
import logo3 from "../../../Assests/NavLogos/logo3.png";
import logo6 from "../../../Assests/NavLogos/logo6.png";
import logo7 from "../../../Assests/NavLogos/logo7.png";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerInfoAndLogos}>
          <div className={styles.info}>
            <img src={logo7} alt="logo" className={styles.logo} />
            <p>
              <strong>Last Updated</strong>:<span> September 5, 2023</span>
            </p>
            <p>
              Website Content Managed by <br />
              <span>Indore Smart City Development Limited</span>
            </p>
          </div>
          <div className={styles.footerLogos}>
            <img src={logo2} alt="logo" className={styles.logo} />
            <img src={logo3} alt="logo" className={styles.logo} />
            <img src={logo6} alt="logo" className={styles.logo} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
