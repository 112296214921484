import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import banner3 from "../../../Assests/HomeLogos/banner3.jpg";
import banner4 from "../../../Assests/HomeLogos/banner4.jpg";
import banner5 from "../../../Assests/HomeLogos/banner5.jpg";
import banner6 from "../../../Assests/HomeLogos/banner6.jpg";
import banner10 from "../../../Assests/HomeLogos/banner10.jpg";
import banner11 from "../../../Assests/HomeLogos/Banner12.png";
import styles from "./Home.module.css";
import { useNavigate } from "react-router-dom";

function Home() {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();

  const images = useMemo(
    () => [banner10, banner3, banner4, banner5, banner6, banner11],
    []
  );

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const registerHandler = () => {
    // setShowRegistrationModal(true);
    navigate("citizenregistration");
  };

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, [images]);

  let content = null;
  if (currentImageIndex === 0) {
    // content = (
    //   <div className={styles.content}>
    //     <h1 className={styles.overlayText}>{t("CM")}</h1>
    //   </div>
    // );
  } else if (currentImageIndex === 1) {
    content = (
      <div className={styles.content}>
        <h1 className={styles.overlayText}>{t("MAYOR")}</h1>
      </div>
    );
  } else if (
    currentImageIndex === 2 ||
    currentImageIndex === 3 ||
    currentImageIndex === 4
  ) {
    content = (
      <div className={styles.content}>
        <h1 className={styles.overlayText}>{t("SOLAR")}</h1>
      </div>
    );
  } else if (currentImageIndex === 5) {
    // No content needed
  }

  const currentImage = images[currentImageIndex];

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img
          src={currentImage}
          alt="Background"
          className={styles.backgroundImage}
        />
      </div>
      <button className={styles.registrationButton} onClick={registerHandler}>
        {t("REGISTER")}
      </button>
      {content}

      {/* {showRegistrationModal && (
        <RegistrationModal onClose={() => setShowRegistrationModal(false)} />
      )} */}
    </div>
  );
}

export default Home;
