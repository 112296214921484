import React from "react";
import { useNavigate } from "react-router-dom";
import star from "../../../../Assests/Vendor/vendorProducts/star.png";
import mono from "../../../../Assests/Vendor/vendorProducts/Solar2.png";
import Navbar from "../../../LandingPage/Navbar/Navbar";
import Footer from "../../../LandingPage/Footer/Footer";

const VendorProductCardList = () => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    console.log("Navigating...");
    navigate("/vendorproductpage");
  };

  return (
    <main className="bg-[#8A8F93] w-full h-auto mb-10">
      <Navbar />
      <div className="bg-[#8A8F93] w-full">
        <div className="bg-white w-[85%]  mx-auto my-8">
          <div>
            <div className="p-8">
              <h1 className="s:text-[14px] md:text-[16px] lg:text-xl font-semibold text-bluee">
                Monocrystalline Solar Panels (Mono-SI)
              </h1>
            </div>
            <div className="Details s:-mt-10 md:mt-0">
              <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 gap-8 m-12 mr-5 ml-5 px-19 ">
                <div
                  className="text-center border shadow-lg rounded mb-6"
                  onClick={navigateHandler}
                >
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>

                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      <img src={star} alt="" className="max-w-[100px] mb-2 " />
                      <span className="ml-2 text-bluee font-bold">11018</span>
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      <img src={star} alt="" className="max-w-[100px] mb-2 " />
                      <span className="ml-2 text-bluee font-bold">11018</span>
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      <img src={star} alt="" className="max-w-[100px] mb-2 " />
                      <span className="ml-2 text-bluee font-bold">11018</span>
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>
              </div>

              <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 gap-8 m-12 mr-5 ml-5 px-19 ">
                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>

                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p class="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>
              </div>

              <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 gap-8 m-12 mr-5 ml-5 px-19 ">
                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>

                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>
              </div>

              <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 gap-8 m-12 mr-5 ml-5 px-19 ">
                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>

                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>

                <div className="text-center shadow-lg rounded mb-6">
                  <div className="overflow-hidden">
                    <img src={mono} alt="" className="" />
                  </div>
                  <div className="text-left m-3">
                    <p className="py-2 font-bold text-[20px] ">
                      Monocrystalline Solar Panels (Mono-SI)
                    </p>
                    <p className="py-2 text-[13px] ">
                      Find the right products that fits your budget and needs.
                    </p>
                    <div className="flex">
                      {" "}
                      <img
                        src={star}
                        alt=""
                        className="max-w-[100px] mb-2 "
                      />{" "}
                      <span className="ml-2 text-bluee font-bold">11018</span>{" "}
                    </div>
                    <span className="font-bold">₹29,900</span>
                    <span className="text-[13px]">(GST inclusive price)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default VendorProductCardList;
