import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

function SixthPage() {
  const [isOpen, setIsOpen] = useState(false);

  const showSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Fragment>
      <div
        className={`md:w-68 bg-gray-800 ${
          isOpen ? "block" : "hidden"
        } md:block fixed md:relative`}
      >
        <div className="sidebar space-y-5 font-semibold text-sm text-white mt-[120px] cursor-pointer ">
          <div className="sidebar xl2:w-[350px] s:w-[200px] bg-[#235688] justify-center space-y-3 xl2:pl-9 s:pl-3 md:pl-2 top-0 pt-14 h-[100%] md:w-[270px] bg-fixed fixed p-8">
            <Link to="/firstPage">
              <p className="hover:text-slate-300">All About Solar Power</p>
            </Link>

            <Link to="/secondPage">
              <p className="hover:text-slate-300">Solar Panel Installation</p>
            </Link>

            <Link to="/thirdPage">
              <p className="hover:text-slate-300">
                Exploring Different Types of Solar Panels{" "}
              </p>
            </Link>

            <Link to="/fourthPage">
              <p className="hover:text-slate-300">
                Calculating the Budget for Solar Installation
              </p>
            </Link>

            <Link to="/fifthPage">
              <p className="hover:text-slate-300">
                A Guide to Finding the Right Solar Installer{" "}
              </p>
            </Link>

            <Link to="/sixthPage">
              <p className="hover:text-slate-300">
                Steps to Get Started with Solar Energy
              </p>
            </Link>

            <Link to="/seventhPage">
              <p className="hover:text-slate-300">
                In Which Category Dom You Belong to?
              </p>
            </Link>
            <br />

            <Link to="/citizendashboard">
              <p className="hover:text-slate-300">Back</p>
            </Link>
          </div>
        </div>
      </div>
      <button
        className="menu-bar md:hidden p-3 z-999 absolute "
        id="menuBar"
        onClick={showSidebar}
      >
        <i className="fas fa-bars fa-xl"></i>
      </button>
      <div className="container flex">
        <div className="container md:ml-[270px] xl2:ml-[350px] s:ml-0 md:mt-[-120px]">
          <div className="content">
            <p className="font-semibold md:text-3xl s:text-2xl pt-5 pl-4">
              Steps to Get Started with Solar Energy
            </p>

            <div className="pl-4 md:pt-16 s:pt-10">
              <p className="font-semibold md:text-2xl s:text-lg">
                Qualities to Look for in a Solar Installer
              </p>
            </div>

            <div className="pl-4 pt-5">
              <p>
                If you're thinking about using solar energy, you're heading
                towards a greener and more sustainable future. Switching to
                solar power not only helps lower your environmental impact but
                also provides long-term financial advantages. To help you begin
                your solar journey, here are some key steps to follow.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p className="font-semibold md:text-2xl s:text-lg">
                Assess Your Energy Usage:
              </p>
              <p>
                Start by understanding how much energy you currently consume.
                Review your electricity bills to determine your usage and
                identify peak periods. This analysis will help you determine the
                size of the solar energy system you need.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p className="font-semibold md:text-2xl s:text-lg">
                Research and Seek Advice:
              </p>
              <p>
                Educate yourself about solar energy and its different aspects.
                Explore various solar technologies, incentives, and financing
                options. Consult reliable solar companies for expert guidance
                and obtain multiple quotes to compare.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p className="font-semibold md:text-2xl s:text-lg">
                Site Evaluation:
              </p>
              <p>
                Arrange a site assessment with a solar professional. They will
                assess your property's orientation, shading, and structural
                integrity to determine the best placement for solar panels. This
                assessment ensures maximum energy generation and system
                efficiency.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p className="font-semibold">Financial Planning:</p>
              <p>
                Consider your budget and explore financing options like solar
                loans, leases, or power purchase agreements (PPAs). Look into
                available incentives, tax credits, and grants that can reduce
                upfront costs and increase returns on investment.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p className="font-semibold">Choose a Qualified Installer:</p>
              <p>
                Select a trustworthy and experienced solar installer for a
                high-quality installation. Consider certifications, customer
                reviews, and warranties when evaluating potential installers.
                Request information on system design, equipment specifications,
                and estimated energy production.
              </p>
            </div>

            <div className="pl-4 pt-8">
              <p>Obtain Permits and Approvals:</p>
              <p className="pb-10">
                Understand local regulations and acquire the necessary permits
                for solar panel installation. Your installer should assist you
                in this process, ensuring compliance with building codes and
                utility requirements
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SixthPage;
