// import React from "react";
// import styles from "./Usp.module.css";
// import UspImage1 from "../../../Assests/UspLogos/usp1.png";
// import UspImage2 from "../../../Assests/UspLogos/usp2.png";
// import UspImage3 from "../../../Assests/UspLogos/usp3.png";

// const Usp = () => {
//   return (
//     <div className={styles.container}>
//       <h1>USP's</h1>
//       <div className={styles.content}>
//         <div className={styles.containerWrapper}>
//           <div className={styles.imageContainer}>
//             <img src={UspImage1} alt="USP 1" />
//           </div>
//           <div className={styles.textContainer}>
//             <p>
//               <strong>Take Control of Your Solar Panel Budget</strong>
//               <br /> Planning to switch to solar energy? Take control of your
//               budget with the Solar Budget Calculator. Easily estimate the cost
//               of installing solar panels and make an informed decision that fits
//               your financial goals. Say goodbye to surprises and hello to
//               affordable renewable energy.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className={styles.content}>
//         <div className={styles.containerWrapper}>
//           <div className={styles.imageContainer}>
//             <img src={UspImage2} alt="USP 2" />
//           </div>
//           <div className={styles.textContainer}>
//             <p>
//               <strong>Find Trusted Partner for your Solar Journey</strong>{" "}
//               <br />
//               Don't waste time searching for reliable solar vendors. With Har
//               Ghar solar, easily discover reputable vendors who can help you
//               with your sustainable energy journey. From installation to
//               maintenance, find the perfect partner for all your solar panel
//               needs.
//             </p>
//           </div>
//         </div>
//       </div>

//       <div className={styles.content}>
//         <div className={styles.containerWrapper}>
//           <div className={styles.imageContainer}>
//             <img src={UspImage3} alt="USP 3" />
//           </div>
//           <div className={styles.textContainer}>
//             <p>
//               <strong>Make a Difference with Green Certificate</strong>
//               <br />
//               Demonstrate your dedication to a greener future by obtaining our
//               Green Certification. Showcase your commitment to sustainable
//               practices and attract environmentally conscious customers. Join
//               the movement towards a cleaner, more sustainable Indore.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Usp;

import React from "react";
import styles from "./Usp.module.css";
import UspImage1 from "../../../Assests/UspLogos/usp1.png";
import UspImage2 from "../../../Assests/UspLogos/usp2.png";
import UspImage3 from "../../../Assests/UspLogos/usp3.png";
import SorYantra from "../../../Assests/UspLogos/soryantra.png";
import SolarCity from "../../../Assests/UspLogos/solarCity.jpg";

const Usp = () => {
  return (
    <div className={styles.container}>
      <h1>हर घर सोलर</h1>
      <div className={styles.content}>
        <div className={styles.containerWrapper}>
          <div className={styles.imageContainer}>
            <img src={UspImage1} alt="USP1" />
          </div>
          <div className={styles.imageContainer1}>
            <img src={SolarCity} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.containerWrapper}>
          <div className={styles.imageContainer}>
            <img src={UspImage2} alt="USP2" />
          </div>
          <div className={styles.textContainer}>
            <h2>सौर मित्र बनने के फायदे</h2> <br />
            <p>1] बिजली के बिल से छुटकारा ।</p>
            <p>
              2] लगभग 15 वर्षों के लिए मुफ्त बिजली। (5 साल में शुरुआती लागत
              वसूलने के बाद)
            </p>
            <p>
              3] कार्बन उत्सर्जन में कमी के कारण स्वस्थ व सतत वातावरण का निर्माण
              ।
            </p>
            <p>
              4] स्वच्छ ऊर्जा के क्षेत्र में नेक कार्य करने से गर्वित भारतीय
              होने की अनुभूति ।
            </p>
            <p>5] आने वाली पीढ़ी के लिए सतत पर्यावरण का निर्माण ।</p>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.containerWrapper}>
          <div className={styles.imageContainer}>
            <img src={UspImage3} alt="USP 2" />
          </div>
          <div className={styles.imageContainer1}>
            <img src={SorYantra} alt="" />

            {/* <p>
              <strong>सौर मित्र बनने के फायदे</strong> <br />
              <ul className="list-disc">
                <li>बिजली के बिल से छुटकारा ।</li>
                <li>
                  लगभग 15 वर्षों के लिए मुफ्त बिजली। (5 साल में शुरुआती लागत
                  वसूलने के बाद )
                </li>
                <li>
                  कार्बन उत्सर्जन में कमी के कारण स्वस्थ व सतत वातावरण का
                  निर्माण ।
                </li>
                <li>
                  स्वच्छ ऊर्जा के क्षेत्र में नेक कार्य करने से गर्वित भारतीय
                  होने की अनुभूति ।
                </li>
                <li>आने वाली पीढ़ी के लिए सतत पर्यावरण का निर्माण ।</li>
              </ul>
            </p> */}
          </div>
        </div>
      </div>
      {/* 
      <div className="xl2:flex gap-x-10 ">
        <div className="xl2:w-96 xl2:h-96 lg:w-80 lg:h-80 md:w-72 md:h-72 sm:w-52 m:w-52 s:w-40  w-52 mt-2 mx-auto p-2">
          <img src={UspImage3} alt="" />
        </div>

        <div className="s:mb-10 md:mb-24 xl2:mb-0 xl2:w-[850px]  lg:w-[760px] md:w-[600px] sm:w-[300px] m:w-[330px] s:w-[280px] h-80 md:h-56  sm:h-auto s:h-auto bg-white text-black mt-5">
          <img src={SorYantra} className="object-fit md:h-80 sm:h-40 w-full"/>
        </div>
      </div> */}
    </div>
  );
};

export default Usp;
