import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import calculator from "../../../Assests/dashboardAssets/calculator.png";
import calculatorHindi from "../../../Assests/dashboardAssets/calculatorhindi.png";
import awarenessHindi from "../../../Assests/dashboardAssets/awarenesshindi.png";
import greencertificateHindi from "../../../Assests/dashboardAssets/greencertificatehindi.png";
import solarSankalpHindi from "../../../Assests/dashboardAssets/solarsankalphindi.png";
import vendorListHindi from "../../../Assests/dashboardAssets/vendorlisthindi.png";
import vendorlist from "../../../Assests/dashboardAssets/vendorlist.png";
import awareness from "../../../Assests/dashboardAssets/awareness.png";
import solarsankalp from "../../../Assests/dashboardAssets/solarsankalp.png";
import greencertificate from "../../../Assests/dashboardAssets/greencertificate.png";
import logout from "../../../Assests/CitizenImages/logout.png";
import AuthContext from "../../../Context/AuthContext";
import backgroundImg from "../../../Assests/dashboardAssets/backgroundImg.jpg";
import { useTranslation } from "react-i18next";

const CitizenDashboard = () => {
  const AuthCtx = useContext(AuthContext);
  const currentLanguage = AuthCtx.currentLanguage;
  const [calculatorAccess, setCalculatorAccess] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");
  const [isHindi, setIsHindi] = useState(true);

  console.log(currentLanguage);

  const permissionHandle = () => {
    const userAccess = window.confirm(
      "You can apply for a solar subsidy if you own a home. (Living in a multi-family home makes you ineligible.)"
    );
    if (userAccess) {
      setCalculatorAccess(true);
      navigate("/calculator");
    }
  };
const redirectPm=()=>{
  window.location.href = "https://www.pmsuryaghar.gov.in/state-wise-vendor";
}
  return (
    <div
      className="Container flex flex-col justify-center w-full md:h-screen h-auto pb-8 bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImg})`,
      }}
    >
      <div className="absolute inset-0 bg-black opacity-80 s:max-h-[100%] md:h-auto"></div>
      <div className="Buttons-Section flex md:flex-col items-end cursor-pointer justify-end md:space-y md:mr-[7%]">
        <img
          src={logout}
          alt="back"
          className="w-[15%] md:w-[5%] sm:[3%] z-10"
          onClick={() => AuthCtx.logout()}
        />
      </div>
      <div className="Cards-Section flex s:flex-col md:flex-row md:w-[90%] md:mt-[2%] md:ml-[5%] s:ml-8 s:w-[90%] s:space-y-4 md:space-y-0 cursor-pointer justify-center items-center z-50">
        <div className="">
          <img
            src={
              AuthCtx.currentLanguage === true ? calculatorHindi : calculator
            }
            alt=""
            className="w-[90%]"
            style={{ filter: `brightness(200%)` }}
            onClick={permissionHandle}
          ></img>
        </div>
        <div className="" onClick={()=>{
          redirectPm()
        }}>
         
            <img
              src={
                AuthCtx.currentLanguage === true ? vendorListHindi : vendorlist
              }
              alt=""
              className="w-[90%]"
              style={{ filter: `brightness(200%)` }}
            ></img>
     
        </div>
        <div className="">
          <Link to={"/contentrouter"}>
            <img
              src={
                AuthCtx.currentLanguage === true ? awarenessHindi : awareness
              }
              alt=""
              className="w-[90%]"
              style={{ filter: `brightness(200%)` }}
            ></img>
          </Link>
        </div>
        <div className="">
          <Link to={"/resolutionform"}>
            <img
              src={
                AuthCtx.currentLanguage === true
                  ? solarSankalpHindi
                  : solarsankalp
              }
              alt=""
              className="w-[90%]"
              style={{ filter: `brightness(200%)` }}
            ></img>
          </Link>
        </div>
        <div className="">
          <Link to={"/greencertificate"}>
            <img
              src={
                AuthCtx.currentLanguage === true
                  ? greencertificateHindi
                  : greencertificate
              }
              alt=""
              className="w-[90%]"
              style={{ filter: `brightness(200%)` }}
            ></img>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CitizenDashboard;
