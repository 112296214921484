import React, { useEffect, useState } from "react";
import { child, get, ref, remove } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../../../../firebase";
import notificationVendor from "../../../../../Assests/Vendor/notificationVendor.png";
import profile from "../../../../../Assests/Vendor/profile.png";
import product from "../../../../../Assests/Vendor/product.png";
import Small1 from "../../../../../Assests/Vendor/Small1.png";

const PreviousProduct = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState("product");
  const [productData, setProductData] = useState(null);
  const [keys, setKeys] = useState([]);
  const userEmail = localStorage.getItem("currentUser");
  const [stockFilter, setStockFilter] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const emailPath = userEmail.split("@")[0].replace(/[.#$/[\]]/g, "_");
        const dataRef = ref(db, `vendor/${emailPath}/products`);
        const snapshot = await get(child(dataRef, "/"));

        if (snapshot.exists()) {
          const vendorData = snapshot.val();
          const keys = Object.keys(vendorData);
          setKeys(keys);
          const productData = Object.values(vendorData);
          setProductData(productData);
        } else {
          console.log("No data found for the entered email.");
        }
      } catch (error) {
        console.log("Error fetching vendor data;", error);
      }
    };
    fetchData();
  }, []);

  const handleEditProduct = (productId) => {
    navigate(`/vendorupdateproduct/${keys[productId]}`);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      const emailPath = userEmail.split("@")[0].replace(/[.#$/[\]]/g, "_");
      const productRef = ref(
        db,
        `vendor/${emailPath}/products/${keys[productId]}`
      );
      alert("are you sure you want to Delete this product?");

      // Remove the product data from the database
      await remove(productRef);

      // Update the local state to reflect the changes
      const updatedKeys = [...keys];
      updatedKeys.splice(productId, 1);
      setKeys(updatedKeys);

      const updatedProductData = [...productData];
      updatedProductData.splice(productId, 1);
      setProductData(updatedProductData);

      console.log("Product deleted successfully");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <>
      <div className="main flex">
        <div className="block lg:hidden absolute z-50 ml-5 mt-3">
          <button onClick={() => setShowSidebar(!showSidebar)}>
            <i className="fas fa-bars fa-xl"></i>
          </button>
        </div>
        <div
          className={`sidebar s:absolute lg:relative top-0 left-0 ${
            showSidebar ? "block" : "hidden"
          } lg:block s:w-[50%] s:h-[100%] md:w-[25%] md:h-[100vh]  z-40  s:bg-blue-200 lg:bg-white h-screen s:break-words lg:w-[20%] xl2:w-[17%]`}
        >
          <ul className="flex flex-col gap-y-5 pt-28 pl-6 ">
            <Link to="/vendorprofile" onClick={() => setActiveLink("profile")}>
              <li
                className={`flex items-center gap-x-2 ${
                  activeLink === "profile" ? "bg-[#235688] text-white" : ""
                }`}
              >
                <img
                  src={profile}
                  alt=""
                  className="md:w-6 s:w-6 s:opacity-50 md:opacity-100 "
                />
                Profile
              </li>
            </Link>
            <li className="flex items-center gap-x-2 hover:text-white hover:bg-[#235688]  ">
              <img
                src={notificationVendor}
                alt=""
                className="md:w-6 s:w-6 s:opacity-50 md:opacity-100"
              />
              Notification
            </li>
            <Link
              to="/vendorproductlist"
              onClick={() => setActiveLink("product")}
            >
              <li
                className={`flex items-center gap-x-2 ${
                  activeLink === "product" ? "bg-[#235688] text-white" : ""
                }`}
              >
                <img
                  src={product}
                  alt=""
                  className="md:w-6 s:w-6 s:opacity-50 md:opacity-100"
                />
                Product
              </li>
            </Link>
            <div className="space-y-3 lg:hidden">
              <li className="flex items-center gap-x-2 text-sm s:-ml-2 hover:text-white hover:bg-[#235688] hover:p-1 ">
                <i className="fa-hand-point-left fa-solid" />
                Previous Product
              </li>
              <Link to="/vendoraddnewproduct">
                <li className="flex items-center gap-x-2 s:-ml-2  text-sm hover:text-white hover:bg-[#235688] hover:p-1 ">
                  <i className="fa-solid fa-cart-plus"></i>
                  Add New Product
                </li>
              </Link>

              <Link to= "/vendorreview">
              <li className="flex items-center gap-x-2 text-sm s:-ml-2 hover:text-white hover:bg-[#235688] hover:p-1 ">
                <i className="fa-solid fa-face-smile"></i>
                Review & Ratings
              </li>
              </Link>
            </div>
            <li className="flex items-center gap-x-2 break-words">
              <i className="">&larr; LogOut</i>
            </li>
          </ul>
        </div>
        <div className="content h-full s:w-[100%] lg:w-[80%] xl2:w-[83%]">
          <div className="navbar md:w-[100%] s:h-14 md:h-20 bg-white flex justify-end items items-center gap-x-5">
            <img
              className="md:w-6 md:h-auto s:w-6 s:h-auto md:block  "
              src={profile}
              alt=""
            />
            <span className=" md:block s:text-1xl md:text-sm">
              Dhanajay Raj
            </span>
            <div className="bg-gray-400 md:w-8 md:h-8 s:w-6 s:h-6 rounded-full mr-14 md:block">
              <span className="ml-3 font-bold s:text-1 xl md:text-sm s:ml-2 md:ml-3">
                ?
              </span>
            </div>
          </div>
          <div className="middle-content bg-gray-100 w-full min-h-[100vh]  xl2:p-[30px] lg:p-[20px] md:p-[20px] s:p-[20px] m:p-[20px] sm:p-[20px] md:h-[50%]">
            <div className="inner-content bg-white min-h-[100vh] flex overflow-hidden">
              <div className="left-content p-1  border-r-2 border-solid border-gray-100 hidden lg:block ">
                <Link to="/vendorproductlist">
                  <p className="mt-20  lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC]">
                    Product List
                  </p>
                </Link>

                <Link to="/vendoraddnewproduct">
                  <p className="mt-5 lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC]">
                    Add New Product
                  </p>
                </Link>

                <Link to= "/vendorreview">
                <p className="mt-5 lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC]">
                  Review And Rating
                </p>
                </Link>
              </div>
              <div className="right-content">
                <p className="text-[#152C5B] font-semibold xl2:text-xl md:text-xl sm:text-base s:text-sm m:text-[13px] mt-3 md:ml-4 lg:ml-5 xl2:ml-10 s:ml-8 m:ml-5 sm:ml-3 ">
                  Product List
                </p>
                <div className="flex xl2:space-x-8 md:space-x-6  xl2:ml-64 lg:ml-44  md:ml-44    mt-6  m:ml-4 sm:ml-3 ">
                  <button
                    onClick={() => setStockFilter("all")}
                    className="xl2:text-lg lg:text-base md:text-lg sm:text-sm s:text-[9px] text-[#233965] font-semibold hover:bg-[#235688] hover:text-white p-2"
                  >
                    Product List
                  </button>

                  <button
                    onClick={() => setStockFilter("inStock")}
                    className="xl2:text-lg lg:text-base md:text-lg sm:text-sm
                    s:text-[9px] text-[#233965] font-semibold hover:bg-[#235688]
                    hover:text-white p-2"
                  >
                    In Stock
                  </button>
                  <button
                    onClick={() => setStockFilter("outOfStock")}
                    className="xl2:text-lg lg:text-base md:text-lg sm:text-sm s:text-[9px] text-[#233965] font-semibold hover:bg-[#235688] hover:text-white p-2"
                  >
                    Out Of Stock
                  </button>
                </div>
                <hr className=" border-b-2 xl2:w-[66.5vw] lg:w-[66vw] s:w-[100vw]" />
                <div className="flex  md:justify-evenly  s:justify-evenly md:m-5 sm:m-2  s:m-2 sm:text-[12px] md:text-base lg:text-lg s:text-[9px] m:text-[11px] s:-ml-5 md:ml-0">
                  <p>Product Name</p>
                  <p>Status</p>
                  <p>Date</p>
                  <p>Action</p>
                </div>
                <hr className=" border-b-2 xl2:w-[66.5vw] lg:w-[66vw] s:w-[100vw]" />
                <div className="container">
                  <div>
                    {productData && (
                      <table className="min-w-full border border-gray-300 sm:text-[12px] md:text-base lg:text-lg s:text-[9px] m:text-[11px]">
                        <thead>
                          <tr>
                            {/* <th className="border border-gray-300 s:px-1 xl2:px-2 py-2">
                              Select
                            </th> */}
                            <th className="border border-gray-300 s:px-1 xl2:px-2 py-2">
                              Image
                            </th>
                            <th className="border border-gray-300 s:px-0  xl2:px-2 py-2">
                              Product Name
                            </th>
                            <th className="border border-gray-300 s:px-1 xl2:px-2 py-2">
                              Status
                            </th>
                            <th className="border border-gray-300 s:px-1 xl2:px-2 py-2">
                              Date
                            </th>
                            <th className="border border-gray-300 s:px-1 xl2:px-2 py-2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(productData).map((productId, index) => {
                            const product = productData[productId];
                            if (
                              (stockFilter === "inStock" &&
                                product.stockAvailability === "In Stock") ||
                              (stockFilter === "outOfStock" &&
                                product.stockAvailability === "Out Stock") ||
                              stockFilter === "all"
                            ) {
                              return (
                                <tr key={productId}>
                                  {/* <td className="border border-gray-300 s:px-1 xl2:pl-8 py-2">
                                  <input type="checkbox"/>
                                </td> */}
                                  <td className="border border-gray-300 s:px-1 md:px-1 xl2:pl-7 py-2">
                                    <img
                                      src={
                                        product.imageUrl
                                          ? product.imageUrl
                                          : Small1
                                      }
                                      alt=""
                                      className="md:w-12 s:w-6 h-12"
                                    />
                                  </td>
                                  <td className="border border-gray-300 s:px-1 md:px-1  py-2">
                                    {product.productName}
                                  </td>
                                  <td className="border border-gray-300 s:px-1 xl2:pl-9 py-2 ">
                                    <div
                                      className={
                                        product.stockAvailability === "In Stock"
                                          ? "bg-green-100 text-green-500"
                                          : "bg-red-100 text-red-500"
                                      }
                                    >
                                      {product.stockAvailability}
                                    </div>
                                  </td>
                                  <td className="border border-gray-300 s:px-1 xl2:pl-12 py-2">
                                    {product.date}
                                  </td>
                                  <td className="border s:border-gray-300  md:px-1 xl2:pl-10 lg:gap-x-1 gap-y-2 lg:flex py-6">
                                    <button
                                      className="flex text-white bg-green-500 md:px-2 py-1"
                                      onClick={() =>
                                        handleEditProduct(productId)
                                      }
                                    >
                                      <i className="fal fa-edit"></i>Edit
                                    </button>
                                    <button
                                      onClick={() => handleDeleteProduct(index)}
                                      className="flex text-red-600 bg-pink-200 hover:bg-red-600 hover:text-white md:px-1 py-1"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PreviousProduct;
