export const CalculatorData = [
  {
    capacity: 2,
    totalCost: 120000,
    subsidy: 36000,
    netPrice: 84000,
    monthlyGeneration: 240,
    annualGeneration: 2880,
    annualSaving: 20160,
    return: 4,
  },
  {
    capacity: 3,
    totalCost: 180000,
    subsidy: 54000,
    netPrice: 126000,
    monthlyGeneration: 360,
    annualGeneration: 4320,
    annualSaving: 30240,
    return: 4,
  },
  {
    capacity: 4,
    totalCost: 240000,
    subsidy: 63000,
    netPrice: 177000,
    monthlyGeneration: 480,
    annualGeneration: 5760,
    annualSaving: 40320,
    return: 4,
  },
  {
    capacity: 5,
    totalCost: 300000,
    subsidy: 72000,
    netPrice: 228000,
    monthlyGeneration: 600,
    annualGeneration: 7200,
    annualSaving: 50400,
    return: 5,
  },
  {
    capacity: 6,
    totalCost: 360000,
    subsidy: 81000,
    netPrice: 279000,
    monthlyGeneration: 720,
    annualGeneration: 8640,
    annualSaving: 60480,
    return: 5,
  },
  {
    capacity: 7,
    totalCost: 420000,
    subsidy: 90000,
    netPrice: 330000,
    monthlyGeneration: 840,
    annualGeneration: 10080,
    annualSaving: 70560,
    return: 5,
  },
  {
    capacity: 8,
    totalCost: 480000,
    subsidy: 99000,
    netPrice: 381000,
    monthlyGeneration: 960,
    annualGeneration: 11520,
    annualSaving: 80640,
    return: 5,
  },
  {
    capacity: 9,
    totalCost: 540000,
    subsidy: 108000,
    netPrice: 432000,
    monthlyGeneration: 1080,
    annualGeneration: 12960,
    annualSaving: 90720,
    return: 5,
  },
  {
    capacity: 10,
    totalCost: 600000,
    subsidy: 117000,
    netPrice: 483000,
    monthlyGeneration: 1200,
    annualGeneration: 14400,
    annualSaving: 100800,
    return: 5,
  },
  {
    capacity: 11,
    totalCost: 660000,
    subsidy: 117000,
    netPrice: 543000,
    monthlyGeneration: 1320,
    annualGeneration: 15840,
    annualSaving: 110880,
    return: 5,
  },
];
