import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getToken, deleteToken } from "firebase/messaging";
import { messaging } from "./firebase";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { get, ref } from "firebase/database";
import Home from "./components/LandingPage/Home/Home";
import Navbar from "./components/LandingPage/Navbar/Navbar";
import Usp from "./components/LandingPage/USP/Usp";
import AboutSchemes from "./components/LandingPage/AboutSchemes/AboutSchemes";
import Footer from "./components/LandingPage/Footer/Footer";
import CitizenRegistration from "./components/Authentication/Registration/CitizenRegistration/Registration/CitizenRegistration";
// import CitizenEmailVerification from "./components/Authentication/Registration/CitizenRegistration/Email/CitizenEmailVerification";
import CitizenPhoneVerification from "./components/Authentication/Registration/CitizenRegistration/Phone/CitizenPhoneVerification";
import CitizenPassword from "./components/Authentication/Registration/CitizenRegistration/Password/CitizenPassword";
import AdminDashboard from "./components/Dashboard/Admin/AdminDashboard";
import AdminVendorList from "./components/Dashboard/Admin/Pages/AdminVendorList";
import AdminVendorInfo from "./components/Dashboard/Admin/Pages/AdminVendorInfo";
import CitizenDashboard from "./components/Dashboard/Citizen/CitizenDashboard";
import CitizenLogin from "./components/Authentication/Login/CitizenLogin/CitizenLogin";
import CitizenLoginByPhone from "./components/Authentication/Login/CitizenLoginByPhone/CitizenLoginByPhone";
import CitizenLoginByEmail from "./components/Authentication/Login/CitizenLoginByEmail/CitizenLoginByEmail";
import VendorRegistration from "./components/Authentication/Registration/VendorRegistration/Registration/VendorRegistration";
import VendorEmailVerification from "./components/Authentication/Registration/VendorRegistration/Email/VendorEmailVerification";
import VendorPhoneVerification from "./components/Authentication/Registration/VendorRegistration/Phone/VendorPhoneVerification";
import VendorPassword from "./components/Authentication/Registration/VendorRegistration/password/VendorPassword";
import VendorNotification from "./components/Dashboard/Vendor/Pages/VendorNotification/VendorNotification";
import VendorUpdateProduct from "./components/Dashboard/Vendor/Pages/VendorProduct/VendorUpdateProduct";
import VendorProductPage from "./components/Dashboard/Vendor/Pages/VendorProduct/VendorProductPage";
import VendorVerification from "./components/Dashboard/Vendor/Pages/Verification/VendorVerification";
import VendorDashboardTailwind from "./components/Dashboard/Vendor/Dashboard/VendorDashboardTailwind";
import VendorCardDetail from "./components/Dashboard/Vendor/Pages/VendorProduct/VendorCardDetails";
import CalculatorResult from "./components/Dashboard/Citizen/Pages/Calculator/CalculatorResult";
import VendorProductCardList from "./components/Dashboard/Citizen/Pages/VendorProductCardList";
import VendorProfile from "./components/Dashboard/Vendor/Pages/VendorProfile/VendorProfile";
import VendorUpdate from "./components/Dashboard/Vendor/Pages/VendorProfile/VendorUpdate";
import ProductList from "./components/Dashboard/Vendor/Pages/VendorProduct/ProductList";
import VendorDashboard from "./components/Dashboard/Vendor/Dashboard/VendorDashboard";
import AddProduct from "./components/Dashboard/Vendor/Pages/VendorProduct/AddProduct";
import Calculator from "./components/Dashboard/Citizen/Pages/Calculator/Calculator";
import SeventhPage from "./components/About/Content/SeventhPage/SeventhPage";
import VendorLogin from "./components/Authentication/Login/Vendor/VendorLogin";
import VendorHelp from "./components/Dashboard/Vendor/Pages/Help/VendorHelp";
import VendorCards from "./components/Dashboard/Citizen/Pages/VendorCards";
import FourthPage from "./components/About/Content/FourthPage/FourthPage";
import TestHelp from "./components/Dashboard/Vendor/Pages/Help/TestHelp";
import ResolutionForm from "./components/ResolutionForm/ResolutionForm";
import FirstPage from "./components/About/Content/FirstPage/FirstPage";
import SecondPage from "./components/About/Content/SecondPage/Second";
import ThirdPage from "./components/About/Content/ThirdPage/ThirdPage";
import FifthPage from "./components/About/Content/FifthPage/FifthPage";
import SixthPage from "./components/About/Content/SixthPage/SixthPage";
import ResolutionPdf from "./components/ResolutionForm/ResolutionPdf";
import GreenCertificate from "./RoutingNewPages/GreenCertificate";
import Help from "./components/Dashboard/Vendor/Pages/Help/Help";
import ReviewAndRating from "./RoutingNewPages/ReviewsAndRating";
import Rating from "./RoutingNewPages/Rating";
import Likes from "./RoutingNewPages/Likes";
import AdminGreenCertificate from "./components/Dashboard/Admin/Pages/AdminGreenCertificate";

function LandingPage() {
  return (
    <div>
      <Navbar />
      <Home />
      <AboutSchemes />
      <Usp />
      <Footer />
    </div>
  );
}



function App() {
  const navigate = useNavigate();

  // //NOTIFICATION FUNCTION
  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const deleted = await deleteToken(messaging);
          console.log("Token Deleted:", deleted);
        } catch (deleteError) {
          console.error("Error deleting token:", deleteError);
        }

        const token = await getToken(messaging, { vapidKey: "" });
        console.log("Generated TOKEN =>> ", token);
      } else {
        console.log("Permission Denied...");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);


  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/citizenregistration" element={<CitizenRegistration />} />
      {/* <Route
        path="/citizenemailverification"
        element={<CitizenEmailVerification />}
      /> */}
      <Route
        path="/citizenphoneverification"
        element={<CitizenPhoneVerification />}
      />
      <Route path="/citizenpassword" element={<CitizenPassword />} />
      <Route path="/admindashboard" element={<AdminDashboard />} />
      <Route path="/adminvendorlist" element={<AdminVendorList />} />
      <Route
        path="/admingreencertificate"
        element={<AdminGreenCertificate />}
      />
      <Route path="/adminvendorinfo" element={<AdminVendorInfo />} />
      <Route path="/citizendashboard" element={<CitizenDashboard />} />
      <Route path="/citizenlogin" element={<CitizenLogin />} />
      <Route path="/citizenloginbyphone" element={<CitizenLoginByPhone />} />
      <Route path="/citizenloginbyemail" element={<CitizenLoginByEmail />} />
      <Route path="/vendorregistration" element={<VendorRegistration />} />
      <Route path="/vendorpassword" element={<VendorPassword />} />
      <Route path="/vendorlogin" element={<VendorLogin />} />
      <Route
        path="/vendoremailverification"
        element={<VendorEmailVerification />}
      />
      <Route
        path="/vendorphoneverification"
        element={<VendorPhoneVerification />}
      />
      <Route path="/vendordashboard" element={<VendorDashboard />} />
      <Route
        path="/vendordashboardtailwind"
        element={<VendorDashboardTailwind />}
      />
      <Route path="/vendorhelp" element={<VendorHelp />} />
      <Route path="/vendorverification" element={<VendorVerification />} />
      <Route path="/vendorprofile" element={<VendorProfile />} />
      <Route path="/vendorupdate" element={<VendorUpdate />} />
      <Route path="/vendorupdate/:id" element={<VendorUpdate />} />
      <Route path="/vendorproductlist" element={<ProductList />} />
      <Route path="/vendoraddnewproduct" element={<AddProduct />} />
      <Route path="/vendornotification" element={<VendorNotification />} />
      <Route
        path="/vendorupdateproduct/:id"
        element={<VendorUpdateProduct />}
      />
      <Route path="/vendorlikes" element={<Likes />} />
      <Route
        path="/VendorProductCardList"
        element={<VendorProductCardList />}
      />
      <Route path="/vendorrating" element={<Rating />} />
      <Route path="/vendorreview" element={<ReviewAndRating />} />
      <Route path="/vendorproductpeage" element={<VendorProductPage />} />
      <Route path="/help" element={<Help />} />
      <Route path="/testhelp" element={<TestHelp />} />
      <Route path="/contentrouter" element={<FirstPage />} />
      <Route path="/firstPage" element={<FirstPage />} />
      <Route path="/secondPage" element={<SecondPage />} />
      <Route path="/thirdPage" element={<ThirdPage />} />
      <Route path="/fourthPage" element={<FourthPage />} />
      <Route path="/fifthPage" element={<FifthPage />} />
      <Route path="/sixthPage" element={<SixthPage />} />
      <Route path="/seventhPage" element={<SeventhPage />} />
      <Route path="/resolutionform" element={<ResolutionForm />} />
      <Route path="/resolutionpdf" element={<ResolutionPdf />} />
      <Route path="/calculator" element={<Calculator />} />
      <Route path="/calculatorresult" element={<CalculatorResult />} />
      <Route path="/greencertificate" element={<GreenCertificate />} />
      <Route path="/vendorcarddetail/:id" element={<VendorCardDetail />} />
      <Route path="/vendorproductpage" element={<VendorProductPage />} />
      <Route path="/vendorcards" element={<VendorCards />} />
    </Routes>
  );
}

export default App;
