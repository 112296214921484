import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get, push, ref } from "firebase/database";
import { db } from "../../firebase";
import AuthContext from "../../Context/AuthContext";
import nav1 from "../../Assests/NavLogos/logo1.png";
import nav2 from "../../Assests/NavLogos/logo4.png";
import nav3 from "../../Assests/NavLogos/logo5.png";
import nav4 from "../../Assests/NavLogos/logo7.png";

const ResolutionForm = () => {
  const AuthCtx = useContext(AuthContext);
  const navigate = useNavigate();

  function generateUniqueId() {
    let uniqueId = "SOL";
    const randomNumber = Math.floor(Math.random() * 10000000);
    const randomId = randomNumber.toString().padStart(7, "0");
    uniqueId += randomId;
    return uniqueId;
  }

  useEffect(() => {
    const generatedId = generateUniqueId();
    AuthCtx.resolutionForm.SankalpId = generatedId;
  });

  const handleChange = (e) => {
    AuthCtx.setResolutionForm({
      ...AuthCtx.resolutionForm,
      [e.target.name]: e.target.value,
    });
  };

  const navigateHandler = () => {
    navigate("/resolutionpdf");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { Mobile } = AuthCtx.resolutionForm;
    const dataRef = ref(db, `resolutionForm/${Mobile}`);

    get(dataRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          alert(
            "Mobile number already exists! You cannot generate the Sankalp certification twice."
          );
        } else {
          push(dataRef, AuthCtx.resolutionForm)
            .then(() => {
              console.log("Data submitted successfully!");
              navigateHandler();
            })
            .catch((error) => {
              console.error("Error submitting data:", error.message);
            });
        }
      })
      .catch((error) => {
        console.error("Error checking data:", error.message);
      });
  };

  return (
    <main className="bg-[#8A8F93] w-full h-[100vh] mb-10">
      <nav className="bg-white w-full">
        <div className="flex md:gap-x-24 s:gap-x-4 justify-center items-center">
          <img src={nav1} alt="" className="md:w-[7%] s:w-[18%]" />
          <img src={nav2} alt="" className="md:w-[8%] s:w-[20%]" />
          <img src={nav3} alt="" className="md:w-[6%] s:w-[14%]" />
          <img src={nav4} alt="" className="md:w-[6%] s:w-[14%]" />
        </div>
      </nav>

      <div className="bg-[#8A8F93] w-full p-6">
        <div className="bg-white md:my-3 p-5">
          <h1 className="text-2xl font-bold mb-4 text-center">संकल्प पत्र</h1>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto">
            <div className="mb-5">
              <label
                htmlFor="name"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                नाम / Name :-
              </label>
              <input
                type="text"
                id="name"
                name="Name"
                placeholder="अपना नाम दर्ज करें"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="Mobile"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                मोबाइल नंबर / Mobile No :-
              </label>
              <input
                type="number"
                id="mobile"
                name="Mobile"
                placeholder="मोबाइल नंबर दर्ज करें"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="Address"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                शहर/गाँव का पता / Address :-
              </label>
              <input
                type="add"
                id="address"
                name="Address"
                placeholder="आपका पूरा पता यहाँ दर्ज करें"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                required
              ></input>
            </div>
            <div className="mb-5">
              <label
                htmlFor="LightBill"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                अनुमानित बिजली का बिल / Average Light bill :-
              </label>
              <input
                type="number"
                id="lightBill"
                name="LightBill"
                placeholder="बिजली का बिल दर्ज करें"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-7">
              <label
                htmlFor="select-section"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                कितने महीनों में आप संकल्प लेने का निर्णय करेंगे / Resolution
                Time :-
              </label>

              <select
                id="select-section"
                name="ResolutionTime"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                value={AuthCtx.resolutionForm.ResolutionTime}
              >
                <option value="3">3 महीने</option>
                {/* <option value="6">6 महीने</option>
                <option value="12">12 महीने </option> */}
              </select>
            </div>
            <div className="mb-5">
              <label
                htmlFor="name"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                जानकारी का माध्यम / Referred By :-
              </label>
              <input
                type="text"
                id="ReferredBy"
                name="ReferredBy"
                placeholder="जानकारी का माध्यम"
                className="border rounded w-full py-2 px-3 focus:outline-none focus:border-blue-500"
                onChange={handleChange}
                required
              />
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none"
              >
                भेजें
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ResolutionForm;
