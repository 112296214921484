import React, { useState } from "react";

import profile from "../../../../Assests/Vendor/Profile/profile.png";
import notificationVendor from "../../../../Assests/Vendor/notificationVendor.png";
import userEng from "../../../../Assests/Vendor/userEng.png";

export default function AdminGreenCertificate() {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <div className="main flex -p-3">
      <div className="block md:hidden absolute z-50 ml-5 mt-3">
        <button onClick={() => setShowSidebar(!showSidebar)}>
          <i className="fas fa-bars fa-xl"></i>
        </button>
      </div>

      <div
        className={`sidebar s:absolute md:relative top-0 left-0 ${
          showSidebar ? "block" : "hidden"
        } md:block lg:w-[15%] md:w-[17%] s:w-[40%] s:h-[80%] md:h-[100vh] m-2 z-40 s:bg-gray-100 md:bg-white`}
      >
        <ul className="flex flex-col md:gap-y-5 s:gap-y-4  pt-28 pl-6 s:-mr-2  md:mr-4 s:-mt-14 md:-mt-6">
          <li className="md:-mb-2 items-center gap-x-2 s:-ml-3 md:flex  md:text-base s:text-sm font-semibold">
            <img
                src={profile}
              alt=""
              className=" s:w-4 s:opacity-50 md:opacity-100 "
            />
            Profile
          </li>
          <li className="md:-mb-2 items-center gap-x-2 s:-ml-3 md:flex  md:text-base s:text-sm font-semibold">
            <img
                src={profile}
              alt=""
              className=" s:w-4 s:opacity-50 md:opacity-100 "
            />
            Vendor
          </li>
          <li className="md:-mb-2 items-center gap-x-2 s:-ml-3 md:flex  md:text-base s:text-sm font-semibold">
            <img
                src={userEng}
              alt=""
              className=" s:w-4 s:opacity-50 md:opacity-100 "
            />
            User
          </li>
          <li className="md:-mb-2 items-center gap-x-2 s:-ml-3 md:flex  md:text-base s:text-sm font-semibold">
            <img
                src={notificationVendor}
              alt=""
              className=" s:w-4 s:opacity-50 md:opacity-100 "
            />
            Notification
          </li>

          <li className="md:-mb-2 items-center gap-x-2 s:-ml-3 md:flex  md:text-base s:text-sm font-semibold">
            <img
                src={notificationVendor}
              alt=""
              className=" s:w-4 s:opacity-50 md:opacity-100 bg-black"
            />
            Green Certificate
          </li>
          <li className="-ml-2">
            <p>&larr; Logout</p>
          </li>
        </ul>
      </div>

      <div className="content w-full lg:w-[85%] md:w-[83%] s:w-[100%]  h-[100vh] relative z-0">
        <div className="navbar  md:p-10 s:p-8 md:-mt-5 s:-mt-5">
          <div className=" flex items-center   justify-end gap-x-2  md:-mb-5 s:-mb-10  s:text-sm ">
            <img
                src={profile}   
              alt=""
              className="w-7  mt-2 s:w-4 s:opacity-50 md:opacity-100"
            />
            <span className="md:mt-2  s:mt-2 md:ml-0  font-semibold">
              profile
            </span>

            <span className=" md:ml-10 s:ml-10 md:mr-5 md:mt-2 s:mt-2 font-semibold text-red-500">
              Logout
            </span>
          </div>
        </div>

        <div className="bg-gray-400 p-3  min-h-full">
          <div className="bg-white  min-h-[100vh]  xl2:-mb-20 lg:-mb-10 md:-mb-10 ">
            <div className="first-column flex justify-start  ">
              <button
                type="button"
                class="text-white bg-bt  hover:bg-bt  focus:outline-none    font-medium  text-sm p-8 text-center inline-flex items-center mr-2 "
              >
                <span className="text-red-400 md:text-2xl  font-bold s:text-sm">
                  &larr;
                </span>
              </button>
              <div className="  md:p-10  s:p-5 s:-ml-2 w-full text-bluue md:font-bold s:font-semibold md:text-[16px] s:text-[14px] border border-b-4">
                Green Certificate
              </div>
            </div>

            <form className="xl2:m-20 lg:m-16 md:m-14 s:m-5">
              <div className="grid gap-x-20 gap-y-10 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Houes Owner Nme
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Dhanajay Raj"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Adhar Card Number
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="2956-6659-5999"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Register E-mail
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="DhanajayRaj451@gamil.com"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Pan Card Number
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="MBEPR098NMY"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Register Phone No.
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="980-0400-079"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Solar Panel RPF Number
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="JIJDF9888FJU54EGFHUIF0YN"
                    required
                  />
                </div>
                <div class="relative">
                  <label
                    htmlFor=""
                    class="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Electricity Bill
                  </label>
                  <div class="flex items-center border bg-gray-50 dark:bg-gray-700 border-gray-300 text-[#626262] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-blue-500 focus:border-blue-500 p-2.5">
                    <div class="flex-shrink-0">
                      <div className="xl2:ml-52">
                        <i class="fas fa-file text-green-500   dark:text-gray-400"></i>
                        <p>View File</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      id=""
                      class="w-full xl2:h-[150px] lg:h-[100px] ml-2 focus:outline-none"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor=""
                    className="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Register Address
                  </label>
                  <input
                    type="text"
                    id=""
                    className="bg-gray-50 border border-gray-300 text-[#626262] text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="76, Janki Nagar Ext, Near Talent School"
                    required
                  />
                </div>

                <div class="relative">
                  <label
                    htmlFor=""
                    class="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Photo With Solar Panel
                  </label>
                  <div class="flex items-center border bg-gray-50 dark:bg-gray-700 border-gray-300 text-[#626262] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-blue-500 focus:border-blue-500 p-2.5">
                    <div class="flex-shrink-0">
                      <div className="xl2:ml-52">
                        <i class="fas fa-file text-green-500   dark:text-gray-400"></i>
                        <p>View File</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      id=""
                      class="w-full xl2:h-[150px] lg:h-[100px] ml-2 focus:outline-none"
                      required
                    />
                  </div>
                </div>

                <div class="relative xl2:-mt-24 lg:-mt-16 md:-mt-5">
                  <label
                    htmlFor=""
                    class="block mb-2 text-sm font-medium text-[#626262] dark:text-white"
                  >
                    Bill Issued by Vendor
                  </label>
                  <div class="flex items-center border bg-gray-50 dark:bg-gray-700 border-gray-300 text-[#626262] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-blue-500 focus:border-blue-500 p-2.5">
                    <div class="flex-shrink-0">
                      <div className="xl2:ml-52">
                        <i class="fas fa-file text-green-500   dark:text-gray-400"></i>
                        <p>View File</p>
                      </div>
                    </div>
                    <input
                      type="file"
                      id=""
                      class="w-full xl2:h-[150px] lg:h-[100px] ml-2 focus:outline-none"
                      required
                    />
                  </div>

                  <div className="pt-10 pb-10 flex justify-between">
                    <button className="bg-[#00CC76]  hover:bg-[#00CC76] text-white font-bold xl2:py-3 xl2:px-20 lg:py-2 lg:px-10 md:py-2 md:px-3 s:py-2 s:px-6 m:py-2 m:px-10 sm:py-2 sm:px-10">
                      Approve
                    </button>
                    <button className="bg-[#00000014] ml-3 hover:bg-[#00000014] text-gray-400 font-bold xl2:py-3 xl2:px-20  lg:py-2 lg:px-10 md:py-2 md:px-3 s:py-2 s:px-6 m:py-2 m:px-10 sm:py-2 sm:px-10">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
