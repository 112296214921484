// import React from "react";
// import { useNavigate } from "react-router-dom";
// import VendorList from "../../../../Data/VendorList";
// import card1 from "../../../../Assests/Vendor/card1.png";
// import like from "../../../../Assests/Vendor/like.png";
// import nav1 from "../../../../Assests/NavLogos/logo1.png";
// import nav2 from "../../../../Assests/NavLogos/logo4.png";
// import nav3 from "../../../../Assests/NavLogos/logo5.png";
// import nav4 from "../../../../Assests/NavLogos/logo7.png";
// import Footer from "../../../LandingPage/Footer/Footer";

// const VendorCards = () => {
//   const navigate = useNavigate();

//   const navigateHandler = (id) => {
//     console.log("Navigating...");
//     navigate(`/vendorcarddetail/${id}`);
//   };

//   return (
//     <main className="bg-[#8A8F93] w-full h-auto mb-10">
//       <nav className="bg-white w-full">
//         <div className="flex md:gap-x-24 s:gap-x-4 justify-center items-center">
//           <img src={nav1} alt="" className="md:w-[7%] s:w-[18%]" />
//           <img src={nav2} alt="" className="md:w-[8%] s:w-[20%]" />
//           <img src={nav3} alt="" className="md:w-[6%] s:w-[14%]" />
//           <img src={nav4} alt="" className="md:w-[6%] s:w-[14%]" />
//         </div>
//       </nav>
//       <div className="bg-[#8A8F93] w-full">
//         <div className="bg-white w-[85%]  mx-auto my-10">
//           <div>
//             <div className="p-10">
//               <h1 className="text-2xl font-semibold">Vendor Cards</h1>
//             </div>

//             <div className="flex justify-evenly flex-col">
//               {VendorList &&
//                 VendorList.map((vendor, index) => {
//                   return (
//                     <div
//                       className="mb-10 p-4 bg-gray-100 border-2 border-[#E87800] rounded-sm shadow w-[40%]"
//                       onClick={() => navigateHandler(index)}
//                       key={vendor.id}
//                     >
//                       <div className="flex gap-2">
//                         <div className="">
//                           <img className="w-[150px]" src={card1} alt="" />
//                           <h3 className="text-[14px] text-[#99A3AD] mt-8">
//                             Phone
//                           </h3>
//                           <p className="text-[16px]">{vendor.phone}</p>
//                           <h3 className="text-[14px] text-[#99A3AD] mt-3">
//                             Email
//                           </h3>
//                           <p className="text-[16px]">{vendor.email}</p>
//                         </div>
//                         <div>
//                           <h3 className="text-[18px] font-semibold text-[#000000] flex gap-16">
//                             {vendor.companyName}
//                             <span>
//                               <img src={like} alt="" className="w-5" />
//                             </span>
//                           </h3>
//                           <h3 className="text-[14px] text-[#99A3AD] mt-4">
//                             Contractor ID
//                           </h3>
//                           <p className="text-[16px]">{vendor.contractorID}</p>
//                           <h3 className="text-[14px] text-[#99A3AD] mt-3">
//                             Address
//                           </h3>
//                           <p className="text-[16px]">{vendor.address}</p>
//                           <p>
//                             <span className="flex justify-end mt-10 text-[#235688]">
//                               View More
//                             </span>
//                           </p>
//                         </div>
//                       </div>
//                     </div>
//                   );
//                 })}
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </main>
//   );
// };

// export default VendorCards;

import React from "react";
import { useNavigate } from "react-router-dom";
import VendorList from "../../../../Data/VendorList";
import card1 from "../../../../Assests/NavLogos/logo7.png";
// import like from "../../../../Assests/Vendor/like.png";
import nav1 from "../../../../Assests/NavLogos/logo1.png";
import nav2 from "../../../../Assests/NavLogos/logo4.png";
import nav3 from "../../../../Assests/NavLogos/logo5.png";
import nav4 from "../../../../Assests/NavLogos/logo7.png";
import Footer from "../../../LandingPage/Footer/Footer";
const VendorCards = () => {
  const navigate = useNavigate();
  const navigateHandler = (id) => {
    console.log("Navigating...");
    navigate(`/vendorcarddetail/${id}`);
  };
  return (
    <main className="bg-[#8A8F93] w-full h-auto mb-10">
      <nav className="bg-white w-full">
        <div className="flex md:gap-x-24 s:gap-x-4 justify-center items-center">
          <img src={nav1} alt="" className="md:w-[7%] s:w-[18%] " />
          <img src={nav2} alt="" className="md:w-[8%] s:w-[20%]" />
          <img src={nav3} alt="" className="md:w-[6%] s:w-[14%]" />
          <img src={nav4} alt="" className="md:w-[6%] s:w-[14%]" />
        </div>
      </nav>
      <div className="bg-[#8A8F93] w-full ">
        <div className="bg-white w-[85%] mx-auto md:my-10 s:my-8">
          <div>
            <div className="p-10">
              <h1 className="text-2xl font-semibold">Vendor Cards</h1>
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl2:grid-cols-2 gap-4 w-[100%]">
              {VendorList &&
                VendorList.map((vendor, index) => {
                  return (
                    <div
                      className="mb-10  p-2 bg-gray-100 border-2 border-[#E87800] rounded-sm shadow w-[80%] mx-auto relative"
                      onClick={() => navigateHandler(index)}
                      key={vendor.id}
                    >
                      <div className="flex gap-2">
                        <div className="w-[40%]">
                          <img className="w-full" src={card1} alt="" />

                          <h3 className="xl2:text-[14px]  lg:text-[12px] md:text-[10px]  xl2:mt-5   sm:text-[12px] s:text-[10px] text-[#99A3AD] mt-2">
                            Contact Person
                          </h3>
                          <p className="xl2:text-[14px]  lg:text-[12px] md:text-[10px] sm:text-[12px] s:text-[10px] break-words">
                            {vendor.contactPerson}
                          </p>
                        </div>
                        <div className="w-[60%]">
                          <h3 className="xl2:text-[16px] lg:text-[13px] md:text-[11px]  sm:text-[13px] s:text-[11px] font-semibold text-[#000000] flex items-center justify-between">
                            {vendor.companyName}
                          </h3>
                          <h3 className="xl2:text-[14px] lg:text-[12px] md:text-[10px]  sm:text-[12px] s:text-[10px] text-[#99A3AD] mt-2">
                            Email
                          </h3>

                          <p className="xl2:text-[14px] lg:text-[12px] md:text-[10px] sm:text-[12px] break-words s:text-[10px] ">
                            {vendor.email}
                          </p>
                          <h3 className="xl2:text-[14px] lg:text-[12px] md:text-[10px]  sm:text-[12px] s:text-[10px] text-[#99A3AD] mt-2">
                            Phone
                          </h3>
                          <p className="xl2:text-[14px] lg:text-[12px] md:text-[10px]  sm:text-[12px] s:text-[10px]">
                            {vendor.phone}
                          </p>
                          <p className="xl2:text-[14px] lg:text-[12px] md:text-[10px]   sm:text-[12px] s:text-[10px] absolute bottom-0 right-2  text-[#235688] cursor-pointer">
                            View More
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};
export default VendorCards;
