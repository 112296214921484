import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Close from "../../../../../Assests/CitizenImages/CloseBtn.png";

const CalculatorResult = () => {
  const location = useLocation();
  const calculatedResult = location.state?.calculatedResult;
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate("/citizendashboard");
  };

  return (
    <div className="bg-[#8A8F93] w-full min-h-screen relative">
      <div className="bg-white m-10  w-full s:w-full sm:p-5 mx-auto">
        <img
          src={Close}
          alt=""
          className="w-8 md:w-12 s:w-8 m-10 ml-[90%] -mt-8 bg-slate-400"
          onClick={closeHandler}
        />
        <div className="w-full s:p-2 md:p-10">
          <div className="content ml-0 mt-0   ">
            <div>
              <h1 className="font-bold text-2xl sm:text-3xl mb-4">
                Solar Rooftop calculator
              </h1>
            </div>

            <hr className="h-px bg-gray-200 border-[2px] dark:bg-gray-900 mt-4 sm:mt-8" />

            <div>
              <p className="s:text-sm sm:text-sm md:text-base mt-3 sm:mt-5">
                Average solar irradiation in MADHYA PRADESH state is 1266.52 W /
                sq.m
              </p>
            </div>

            <div>
              <p className="break-words s:text-sm sm:text-sm md:text-base mt-2 sm:mt-4">
                1kWp solar rooftop plant will generate on an average over the
                year 5.0 kWh of electricity per day (considering 5.5 sunshine
                hours)
              </p>
            </div>

            <div className=" Maincontainer flex flex-col mt-4 sm:flex-row sm:mt-6 rounded w-full">
              <div className="container1 bg-gray-300 w-full pb-6 sm:w-4/5 md:w-4/5 lg:w-3/4 rounded sm:p-6">
                <div className="font-bold flex mt-8">
                  <span className="ml-5">1.</span>
                  <div className="ml-4 text-[15px]">Size of Power Plant</div>
                </div>
                <p className="ml-11 text-[14px]">
                  Feasible Plant size as per your Budget :
                </p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  {calculatedResult}w
                </span>

                <div className="font-bold flex mt-5">
                  <span className="ml-5">2.</span>
                  <div className="ml-4 text-[15px]">Cost of the Plant :</div>
                </div>
                <p className="ml-12  text-[14px]">
                  MNRE current Benchmark Cost{" "}
                  <span className="text-lightgrayy  text-[14px]">
                    (without GST)
                  </span>
                  :
                  <span className="s:block sm:hidden ml-0  font-semibold">
                    {calculatedResult}kw
                  </span>
                </p>
                <p className="ml-12 mt-2 text-[14px]">
                  Without subsidy
                  <span className="text-lightgrayy  text-[14px]">
                    (Based on current MNRE benchmark without GST)
                  </span>
                  :
                  <span className="s:block sm:hidden ml-0  font-semibold">
                    Rs. 600991 (near to your budget)
                  </span>
                </p>
                <p className="ml-12 mt-6  text-[14px]">
                  With subsidy 0
                  <span className="text-lightgrayy text-[14px] ">
                    (Based on current MNRE benchmark without GST)
                  </span>
                  :
                  <span className="s:block sm:hidden ml-0  font-semibold">
                    Rs. 100991
                  </span>
                </p>

                <div className="font-bold flex mt-4 ">
                  <span className="ml-5">3.</span>
                  <div className="ml-4 text-[15px]">
                    Total Electricity Generation from Solar Plant :
                  </div>
                </div>
                <p className="ml-12 mt-1 text-[14px]">Annual :</p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  9000kwh
                </span>

                <p className="ml-12 mt-2 text-[14px]">Life-Time (25 years):</p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  22500kwh
                </span>

                <div className="font-bold flex mt-3">
                  <span className="ml-5">4.</span>
                  <div className="ml-4">Life-Time (25 years):</div>
                </div>

                <div className="font-bold flex">
                  <span className="ml-5">a.</span>
                  <div className="ml-4 text-[15px]">
                    Tariff @ Rs.8/ kWh (for top slab of traffic) - No increase
                    assumed over 25 years :
                  </div>
                </div>
                <p className="ml-12 mt-1 text-[14px]">Monthly :</p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  Rs. 900991
                </span>

                <p className="ml-12 mt-1 text-[14px]">Annual :</p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  Rs. 700991
                </span>

                <p className="ml-12 mt-1 mb-1 text-[14px]">
                  Life-Time (25 years):
                </p>
                <span className="s:block sm:hidden ml-12  font-semibold">
                  Rs. 9880991
                </span>
              </div>

              <div className="container2 bg-[#2356881A] text-xs sm:text-sm w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl2:w-1/5 sm:p-4 s:hidden sm:block">
                <p className="md:ml-0 s:ml-12 sm:ml-0 sm:my-[0px] sm:mt-[120px]  md:my-0 md:mt-14 xl2:mt-11 lg:mt-14 s:mt-10 font-semibold">
                  7kw
                </p>
                <p className="md:ml-0 s:ml-12 sm:ml-0 xl2:mt-12 lg:mt-10 md:mt-16 s:mt-11 sm:my-[0px] sm:mt-[100px]  md:my-0 font-semibold">
                  Rs. 40991 <span className="font-normal"> Rs. / kw</span>
                </p>
                <p className="md:ml-0 s:ml-12 sm:ml-0 md:mt-8 xl2:mt-2 lg:mt-3 s:mt-6  sm:my-[0px] sm:mt-[60px] font-semibold md:my-0">
                  Rs. 600991
                  <div className="text-lightgy font-normal  ayy">
                    (near to your budget)
                  </div>
                </p>
                <p className="md:ml-0 s:ml-12 font-semibold sm:ml-0 md:mt-6 xl2:mt-3 lg:mt-2 s:mt-3  sm:my-[0px] sm:mt-[85px]  md:my-0">
                  Rs. 100991
                  <span className="text-lightgrayy"></span>
                </p>

                <p className="md:ml-0 s:ml-12 sm:ml-0 font-semibold  s:mt-10  md:mt-16 md:my-3 lg:my-3 xl2:my-3 lg:mt-9 xl2:mt-9 sm:my-[0px] sm:mt-[140px]  ">
                  9000kwh
                </p>
                <p className="md:ml-0 s:ml-12 sm:ml-0 font-semibold  mt-3 sm:my-[0px] sm:mt-[25px]  md:my-2 lg:my-0">
                  22500kwh
                </p>

                <p className="md:ml-0 s:ml-12 sm:ml-0 font-semibold  md:mt-[100px] s:mt-14 lg:mt-20 xl2:mt-[63px] sm:my-[0px] sm:mt-[200px]  md:my-0 ">
                  Rs. 900991
                </p>
                <p className="md:ml-0 s:ml-12 sm:ml-0 mt-2 font-semibold ">
                  Rs. 700991
                </p>
                <p className="md:ml-0 s:ml-12 sm:ml-0 mt-2 sm:mt-[24px] font-semibold  md:mt-2 s:mb-5 sm:mb-0">
                  Rs. 9880991
                </p>
              </div>
            </div>
            <div className="disclaimer m-10 md:mt-10 flex flex-col justify-center items-center">
              <h3 className="font-bold text-lg md:text-xl lg:text-xl xl:text-2xl">
                Disclaimer :
              </h3>
              <p className="text-center text-sm md:text-base lg:text-lg xl:text-xl">
                The calculation is indicative in nature; generation may vary
                from location to location.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculatorResult;
