import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ref, get, child } from "firebase/database";
import { db } from "../../../../../firebase";
import AuthContext from "../../../../../Context/AuthContext";
import profile from "../../../../../Assests/Vendor/profile.png";
import notificationVendor from "../../../../../Assests/Vendor/notificationVendor.png";
import product from "../../../../../Assests/Vendor/product.png";
import userEng from "../../../../../Assests/Vendor/userEng.png";
import person from "../../../../../Assests/Vendor/help.png";

const VendorInput = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [vendorId, setVendorId] = useState([]);
  const [activeLink, setActiveLink] = useState("profile");
  const currentUser = localStorage.getItem("currentUser");
  const AuthCtx = useContext(AuthContext);
  const imageUrl = AuthCtx.vendorProfileImage;

  console.log("vendorID=>",vendorId)
  console.log("profile imagefrom ctx=>", imageUrl)

  const [vendorFormData, setVendorFormData] = useState({
    contractorId: "",
    vendorName: "",
    address: "",
    email: "",
    mobile: "",
    open: "",
    closing: "",
  });

  useEffect(() => {
    const fetchVendor = async (currentUser) => {
      try {
        const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
        const dataRef = ref(db, `vendor/${emailPath}`);
        const snapshot = await get(child(dataRef, "/"));
        AuthCtx.fetchVendorImages(`vendor/${emailPath}/profile`);
  
        if (snapshot.exists()) {
          const vendorData = snapshot.val();
          const keys = Object.keys(vendorData);
          const firstKey = keys[0]; // Get the zeroth key
         console.log(firstKey);
          setVendorId(firstKey);
  
          const data = vendorData[firstKey];
          setVendorFormData({
            contractorId: data.contractorId || "",
            vendorName: data.vendorName || "",
            address: data.address || "",
            email: data.email || "",
            mobile: data.mobile || "",
            open: data.open || "",
            closing: data.closing || "",
          });
        } else {
          console.log("No data found for the entered email.");
        }
      } catch (error) {
        console.log("Error fetching vendor data:", error);
      }
    };
  
    fetchVendor(currentUser);
  }, []);

  return (
    <div className="main flex">
      <div className="block lg:hidden absolute z-50 ml-5 mt-3">
        <button onClick={() => setShowSidebar(!showSidebar)}>
          <i className="fas fa-bars fa-xl"></i>
        </button>
      </div>
      <div
        className={`sidebar s:absolute lg:relative top-0 left-0 ${
          showSidebar ? "block" : "hidden"
        } lg:block s:w-[50%] s:h-[90%] md:w-[25%] md:h-[100vh]  z-40  s:bg-white lg:bg-white h-screen s:break-words lg:w-[20%] xl2:w-[17%]`}
      >
        <ul className="flex flex-col gap-y-5 pt-28 pl-6 ">

        <Link to = "/vendordashboard">
          <li className="flex items-center gap-x-2">
            <img src={notificationVendor} alt="" className="w-7" />
            Dashboard
          </li>
          </Link>

          <Link to="/vendorprofile" onClick={() => setActiveLink("profile")}>
            <li
              className={`flex items-center gap-x-2 ${
                activeLink === "profile" ? "bg-[#235688] text-white" : ""
              }`}
            >
              <img src={profile} alt="" className="w-7 text-white" />
              Profile
            </li>
          </Link>

          <Link to = "/vendornotification">
          <li className="flex items-center gap-x-2">
            <img src={notificationVendor} alt="" className="w-7" />
            Notification
          </li>
          </Link>
          <Link to="/vendorproductlist">
            <li className="flex items-center gap-x-2">
              <img src={product} alt="" className="w-7" />
              Product
            </li>
          </Link>
          <li className="flex items-center gap-x-2 break-words ">
            <img src={userEng} alt="" className="w-7" />
            User Engagement
          </li>
        </ul>
      </div>

      <div className="content h-full s:w-[100%] lg:w-[80%] xl2:w-[83%]">
        <div className="navbar md:w-[100%] h-[15%] s:h-14 md:h-[15%] bg-white flex justify-end items items-center gap-x-5">
          <img
            className="md:w-10 md:h-12 s:w-12 s:h-10 md:block rounded-full"
            src={imageUrl ? imageUrl : person}
            alt=""
          />
          <span className=" md:block s:text-1xl md:text-sm">
            {vendorFormData.vendorName}
          </span>
          <div className="bg-gray-400 md:w-8 md:h-8 s:w-6 s:h-6 rounded-full mr-14 md:block">
            <span className="ml-3 font-bold s:text-1 xl md:text-sm s:ml-2 md:ml-3">
              ?
            </span>
          </div>
        </div>

        <div className="middle-content bg-blue-100 w-full h-[100%] md:p-[30px] s:p-[20px]">
          <Link to={`/vendorupdate/${vendorId}`}>
            <div className="fourth-column s:ml-[250px] z-50  sm:ml-[320px] m:ml-[270px] xl2:ml-[950px] md:ml-[650px] md:mt-24 s:mt-12 ">
              <i className="fa-regular fa-pen-to-square fa-xl"></i>
            </div>
          </Link>
          <div className="form bg-white p-8 flex s:flex-col md:flex-row md:ml-0 md:mr-0 m:ml-8 m:mr-8 md:-mt-28 s:-mt-16">
            <div className="first-column sm:ml-6 md:-ml-8 xl2:ml-8">
              <div className="">
                <p className="font-bold text-2xl md:mt-10 md:ml-10 text-blue-900">
                  Profile
                </p>
              </div>

              <div
                className="relative s:w-40 s:h-40  rounded-full overflow-hidden border border-orange-500 bg-[#D9D9D9]
                              md:ml-10 s:ml-8 md:mt-8 s:mt-6  "
              >
                <img
                  className="object-cover w-full h-full"
                  src={imageUrl ? imageUrl : person}
                  alt=""
                />
              </div>

              <div className="relative overflow-hidden w-6 h-6 s:ml-40 lg:ml-[165px] rounded-full bg-orange-500 mt-[-40px]">
                <button className="text-2xl text-white pl-[4px]  mt-[-7px] hover:">
                  +
                </button>
              </div>
            </div>

            <div className="second-column md:mt-16 md:ml-7 sm:ml-8 xl2:ml-14">
              <p className="hidden md:block">
                <i className="fa-solid fa-user"></i>&emsp; Profile
              </p>
              <div className="mt-20">
                <p className="text-xl font-semibold">Shop Name</p>
                <p className="mt-2">{vendorFormData.vendorName}</p>
              </div>
              <div className="mt-6">
                <p className="text-xl font-semibold">Contractor ID</p>
                <p className="mt-2">{vendorFormData.contractorId}</p>
              </div>
              <div className="mt-6">
                <p className="text-xl font-semibold">Phone No.</p>
                <p className="mt-2">{vendorFormData.mobile}</p>
              </div>
              {/* <div className="md:ml-8 s:ml-10 xl2:ml-20 md:mt-1 xl2:mt-7">
              <Link to = {`/vendorupdate/${vendorId}`}>
                <button
                   onClick={navigateToVendorUpdate}
                  className="w-[140px] text-[#00000066] bg-[#D9D9D9] md:mt-14 s:mt-8 h-10"
                  placeholder="Clear Details"
                  type="clear"
                  name="cleardetail"
                >
                  Clear Details  

                </button>
                </Link>
              </div> */}
            </div>

            <div className="third-column md:mt-[166px] s:mt-10 md:ml-14 lg:ml-16 xl2:ml-24">
              <div>
                <p className="text-xl font-semibold">Shop Timing</p>
                <div className="flex mt-2">
                  <div className="">
                    <p className="text-lg font-medium">Open</p>
                    <p className="mt-2">{vendorFormData.open}</p>
                  </div>
                  <div>
                    <p className="text-lg font-medium s:ml-12 md:ml-6">
                      Closing
                    </p>
                    <p className="mt-2 ml-7">{vendorFormData.closing}</p>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <p className="text-xl font-semibold">Email</p>
                <p className="mt-2">{vendorFormData.email}</p>
              </div>

              <div className="mt-6">
                <p className="text-xl font-semibold">Address</p>
                <p className="mt-2">{vendorFormData.address}</p>
              </div>
            </div>

            {/* <div className="fourth-column mt-14 md:ml-0 lg:ml-3 xl2:ml-8 hidden md:block">
            <Link to = {`/vendorupdate/${vendorId}`}>
              <i className="fa-regular fa-pen-to-square fa-xl"></i>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorInput;
