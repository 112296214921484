import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../../firebase";
// import VerificationModal from "./VerificationModal";
import AuthContext from "../../../../../Context/AuthContext";
import googleLogin from "../../../../../Assests/AuthLogos/google.png";
import facebookLogin from "../../../../../Assests/AuthLogos/facebook.png";
import backArrow from "../../../../../Assests/AuthLogos/backArrow.png";
import sun from "../../../../../Assests/AuthLogos/sun.png";
import styles from "./CitizenRegistration.module.css";

const CitizenRegistration = () => {
  const navigate = useNavigate();
  const AuthCtx = useContext(AuthContext);
  const citizenFormData = AuthCtx.citizenFormData;
  // const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = {
      ...citizenFormData,
      [name]: value,
    };
    AuthCtx.updateCitizenFormData(updatedData);
  };

  // const handleShowVerificationModal = () => {
  //   setShowVerificationModal(true);
  //   AuthCtx.login(citizenFormData);
  // };

  // const handleVerificationModalClose = () => {
  //   setShowVerificationModal(false);
  // };

  //Google Sign In
  const handleGoogleSignIn = () => {
    AuthCtx.handleGoogleSignIn();
  };

  //Facebook Signin
  const handleFacebookSignIn = () => {
    AuthCtx.handleFacebookSignIn();
  };

  //Phone OTP
  const handlePhoneVerification = async () => {
    try {
      console.log("Sending Otp...");

      const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
        callback: (response) => {
          console.log("RECAPTCHA verified:", response);
        },
      });

      const phoneNumber = "+91" + citizenFormData.mobile;

      setTimeout(async () => {
        const confirmation = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          recaptcha
        );
        AuthCtx.setUser(confirmation);
        alert("OTP Sent Successfully...");
        navigate("/citizenphoneverification");
      }, 2000);
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  // const handlePhoneVerification = async () => {
  //   try {
  //     console.log("Sending Otp...");
  //     const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
  //       size: "invisible",
  //       callback: (response) => {
  //         console.log("reCAPTCHA verified:", response);
  //       },
  //     });
  //     // const phoneNumber =
  //     //   AuthCtx.userType === "CITIZEN"
  //     //     ? "+91" + citizenFormData.mobile
  //     //     : "+91" + vendorFormData.mobile;
  //     const phoneNumber = "+91" + citizenFormData.mobile;
  //     const confirmation = await signInWithPhoneNumber(
  //       auth,
  //       phoneNumber,
  //       recaptcha
  //     );
  //     AuthCtx.setUser(confirmation);
  //     alert("OTP Sent Successfully...");

  //     // if (contractorId) {
  //     //   console.log("vendoremailverification");
  //     //   navigate("/vendorphoneverification");
  //     // } else {
  //     //   console.log("citizenemailverification");
  //     //   navigate("/citizenphoneverification");
  //     // }
  //     navigate("/citizenphoneverification");
  //   } catch (error) {
  //     console.error("Error sending OTP:", error);
  //   }
  // };

  const loginHandler = () => {
    navigate("/citizenlogin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // handleShowVerificationModal();
    await handlePhoneVerification();
    console.log("Form Data:", citizenFormData);
    localStorage.setItem("newuser", citizenFormData.email);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftPanel}>
        <div className={styles.welcomeText}>
          <h1>Welcome to HGS</h1>
          <p>Harness the Power of Sun with Har Ghar Solar</p>
          <button className={styles.loginButton} onClick={loginHandler}>
            Login
          </button>
        </div>
      </div>
      <div className={styles.rightPanel}>
        <form className={styles.signupForm} onSubmit={handleSubmit}>
          <div className={styles.heading}>
            <h2>Sign Up To</h2>
            <img src={sun} alt="Sun" className={styles.sunImage} />
          </div>
          <div className={styles.formField}>
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Enter first name"
              required
              maxLength="50"
              value={citizenFormData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Enter last name"
              required
              maxLength="50"
              value={citizenFormData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
              required
              value={citizenFormData.email}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formField}>
            <label htmlFor="mobile">Mobile Number</label>
            <input
              type="tel"
              name="mobile"
              id="mobile"
              placeholder="Enter mobile number"
              required
              pattern="[0-9]{10}"
              value={citizenFormData.mobile}
              onChange={handleChange}
            />
          </div>
          <button
            className={styles.signupButton}
            type="submit"
            // onClick={}
          >
            Sign Up
          </button>
        </form>
        <p className={styles.orText}>OR</p>
        <div className={styles.socialLogin}>
          <img
            src={googleLogin}
            alt="Google"
            className={styles.logo}
            onClick={handleGoogleSignIn}
          />
          <img
            src={facebookLogin}
            alt="Facebook"
            className={styles.logo}
            onClick={handleFacebookSignIn}
          />
        </div>
        <div>
          <img
            src={backArrow}
            alt="back"
            className={styles.arrow}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div id="recaptcha"></div>

      {/* {showVerificationModal && (
        <VerificationModal onClose={handleVerificationModalClose} />
      )} */}
    </div>
  );
};

export default CitizenRegistration;

// Code By © Vivek Raut
