import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import campany from "../../../../../Assests/Vendor/vendorProducts/campany.png";
import mono from "../../../../../Assests/Vendor/vendorProducts/Solar3.png";
import star from "../../../../../Assests/Vendor/vendorProducts/star.png";
import Footer from "../../../../LandingPage/Footer/Footer";
import nav1 from "../../../../../Assests/NavLogos/logo1.png";
import nav2 from "../../../../../Assests/NavLogos/logo4.png";
import nav3 from "../../../../../Assests/NavLogos/logo5.png";
import nav4 from "../../../../../Assests/NavLogos/logo7.png";
import VendorList from "../../../../../Data/VendorList";

const VendorCardDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const navigateHandler = () => {
    console.log("Navigating...");
    navigate("/vendorproductcardlist");
  };

  return (
    <main className="bg-[#8A8F93] w-full h-auto mb-10">
      <nav className="bg-white w-full">
        <div className="flex md:gap-x-24 s:gap-x-4 justify-center items-center">
          <img src={nav1} alt="" className="md:w-[7%] s:w-[18%] " />
          <img src={nav2} alt="" className="md:w-[8%] s:w-[20%]" />
          <img src={nav3} alt="" className="md:w-[6%] s:w-[14%]" />
          <img src={nav4} alt="" className="md:w-[6%] s:w-[14%]" />
        </div>
      </nav>
      <div className="bg-[#8A8F93] w-full">
        <div className="bg-white w-[85%]  mx-auto my-8">
          <div>
            <div className="p-8">
              <h1 className="s:text-[14px] md:text-[16px] lg:text-xl font-semibold text-bluee">
                Vendor Details
              </h1>
            </div>
            <div className="Details s:-mt-10 md:mt-0">
              <div>
                <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 md:gap-8 s:gap-7 md:m-12 s:m-16 md:mr-5 s:mr-5 md:ml-5  s:ml-5 md:px-19  s:px-19 ">
                  <div className="   s:-mt-8  s:m-5   md:-mt-5  md:m-10">
                    <img
                      src={campany}
                      alt=""
                      className=" xl2:max-w-[250px] lg:max-w-[200px]  md:w-[280px]  sm:max-w-[200px] m:max-w-[160px] s:w-[150px]"
                    />
                  </div>

                  <div className="s:-mt-8   md:-mt-5 md:-ml-1 lg:ml-12  sm:-ml-1   m:-ml-3   s:-ml-2   ">
                    <div className="text-pd font-bold md:text-[16px] s:text-[14px]">
                      Personal details
                    </div>

                    <p className="pt-6  font-bold md:text-[16px] s:text-[14px]">
                      shop Name
                    </p>
                    <p className="text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      {VendorList[params.id].companyName}
                    </p>

                    <h1 className="md:pt-6 s:pt-4 font-bold md:text-[16px] s:text-[14px]">
                      Contractor ID
                    </h1>
                    <p className="text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      {VendorList[params.id].contractorID}
                    </p>

                    <h1 className="md:pt-12 s:pt-6 font-bold md:text-[16px] s:text-[14px]">
                      Email
                    </h1>
                    <p className="text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      {VendorList[params.id].email}
                    </p>

                    <h1 className="md:pt-12 s:pt-6 font-bold md:text-[16px] s:text-[14px]">
                      Phone
                    </h1>
                    <p className="text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      {VendorList[params.id].phone}
                    </p>
                  </div>

                  <div className="md:-mt-32  lg:mt-24  s:mt-0 -ml-40 max-w-[300px] md:ml-20 lg:ml-20 sm:-ml-1 m:-ml-3   s:-ml-2 ">
                    <h1 className="pb-3 font-bold md:text-[16px] s:text-[14px]">
                      Address
                    </h1>
                    <p className="pb-3 text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      {VendorList[params.id].address}
                    </p>

                    <div className="md:pb-2 md:pt-6 s:pb-1 s:pt-4 font-bold md:text-[16px] s:text-[14px]">
                      Shop Timing
                    </div>
                    <span className="font-semibold md:text-[16px] s:text-[14px]">
                      Open
                    </span>
                    <span className="ml-12 font-semibold md:text-[16px] s:text-[14px]">
                      Close
                    </span>
                    <div></div>
                    <span className="text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      09:30am
                    </span>
                    <span className="ml-6 text-bluue font-semibold md:text-[16px] s:text-[14px]">
                      05:30pm
                    </span>
                  </div>
                </div>

                <hr className="h-px bg-gray-200 border-[1px] dark:bg-gray-900" />

                <div className="md:m-14 s:m-4">
                  <h1 className="font-bold md:text-[16px] s:text-[14px]">
                    Likes
                  </h1>

                  <div className="Likesss md:mt-5 s:mt-2 flex ">
                    <img
                      src={star}
                      className="md:max-w-[100px] s:max-w-[80px] h-5 "
                      alt=""
                    />
                    <span className="md:ml-2 font-bold md:text-[16px] s:text-[10px]">
                      4 out of 5
                    </span>
                    <span className="md:ml-20 s:ml-8 font-bold md:text-[16px] s:text-[12px]">
                      200021 global likes
                    </span>
                  </div>

                  <div className=" md:m-5 md:mb-20 ">
                    <div className="flex items-center mt-4">
                      <a
                        href="/"
                        className="md:text-[16px] s:text-[12px] font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        5 star
                      </a>

                      <div className="w-[400px] h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                        <div className="h-5  bg-ornge  max-w-[60%]"></div>
                      </div>
                      <span className="md:text-[16px] s:text-[12px] font-medium text-gray-500 dark:text-gray-400">
                        60%
                      </span>
                    </div>
                    <div className="flex items-center mt-4">
                      <a
                        href="/"
                        className="md:text-[16px] s:text-[12px] font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        4 star
                      </a>
                      <div className="w-[400px] h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                        <div className="h-5  bg-ornge max-w-[20%]"></div>
                      </div>
                      <span className="md:text-[16px] s:text-[12px] font-medium text-gray-500 dark:text-gray-400">
                        20%
                      </span>
                    </div>
                    <div className="flex items-center mt-4">
                      <a
                        href="/"
                        className="md:text-[16px] s:text-[12px] font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        3 star
                      </a>
                      <div className="w-[400px] h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                        <div className="h-5  bg-ornge max-w-[70%]"></div>
                      </div>
                      <span className="md:text-[16px] s:text-[12px] font-medium text-gray-500 dark:text-gray-400">
                        70%
                      </span>
                    </div>
                    <div className="flex items-center mt-4">
                      <a
                        href="/"
                        className="md:text-[16px] s:text-[12px] font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        2 star
                      </a>
                      <div className="w-[400px] h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                        <div className="h-5  bg-ornge max-w-[30%]"></div>
                      </div>
                      <span className="md:text-[16px] s:text-[12px] font-medium text-gray-500 dark:text-gray-400">
                        30%
                      </span>
                    </div>
                    <div className="flex items-center mt-4">
                      <a
                        href="/"
                        className="md:text-[16px] s:text-[12px] font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        1 star
                      </a>
                      <div className="w-[400px] h-5 mx-4 bg-gray-200 rounded dark:bg-gray-700">
                        <div className="h-5 bg-ornge max-w-[9%]"></div>
                      </div>
                      <span className="md:text-[16px] s:text-[12px] font-medium text-gray-500 dark:text-gray-400">
                        9%
                      </span>
                    </div>
                  </div>

                  <div className="product details md:mb-28 mb-28">
                    <div className="md:m-7 s:m-5 flex">
                      <div className="flex-1 text-pd font-bold md:text-[16px] s:text-[14px]">
                        ProductDetails
                      </div>
                      <div
                        className="text-bluue font-bold md:text-[16px] s:text-[14px] cursor-pointer"
                        onClick={navigateHandler}
                      >
                        See All
                      </div>
                    </div>

                    <div className="max-w-[1320px] mx-auto grid lg:grid-cols-4 md:grid-cols-2 gap-8 m-12 mr-5 ml-5 px-19 ">
                      <div className="text-center shadow-lg rounded mb-5">
                        <div className="overflow-hidden">
                          <img src={mono} alt="" />
                        </div>

                        <div className="text-left m-3">
                          <p className="py-2 font-bold md:text-[20px] lg:text-[16px] s:text-[16px] xl2:text-[20px] ">
                            Monocrystalline Solar Panels (Mono-SI)
                          </p>
                          <p className="md:py-2 s:py-1 md:text-[13px] s:text-[11px] lg:text-[12px] xl2:text-[13px]">
                            Find the right products that fits your budget and
                            needs.
                          </p>
                          <div className="flex">
                            {" "}
                            <img
                              src={star}
                              alt=""
                              className="md:max-w-[100px] s:max-w-[80px] md:mb-2 s:mb-2 "
                            />{" "}
                            <span className="ml-2 text-bluee font-bold md:text-[16px] s:text-[12px] lg:text-[13px] xl2:text-[16px]">
                              11018
                            </span>{" "}
                          </div>
                          <span className="font-bold md:text-[16px] s:text-[12px] lg:text-[14px] xl2:text-[16px]">
                            ₹29,900
                          </span>
                          <span className="md:text-[13px] s:text-[10px] lg:text-[14px] xl2:text-[13px]">
                            (GST inclusive price)
                          </span>
                        </div>
                      </div>

                      <div className="text-center shadow-lg rounded mb-5">
                        <div className="overflow-hidden">
                          <img src={mono} alt="" className="" />
                        </div>

                        <div className="text-left m-3">
                          <p className="py-2 font-bold md:text-[20px] lg:text-[16px] s:text-[16px] xl2:text-[20px] ">
                            Monocrystalline Solar Panels (Mono-SI)
                          </p>
                          <p className="md:py-2 s:py-1 md:text-[13px] s:text-[11px] lg:text-[12px] xl2:text-[13px]">
                            Find the right products that fits your budget and
                            needs.
                          </p>
                          <div className="flex">
                            {" "}
                            <img
                              src={star}
                              alt=""
                              className="md:max-w-[100px] s:max-w-[80px] md:mb-2 s:mb-2 "
                            />{" "}
                            <span className="ml-2 text-bluee font-bold md:text-[16px] s:text-[12px] lg:text-[13px] xl2:text-[16px]">
                              11018
                            </span>{" "}
                          </div>
                          <span className="font-bold md:text-[16px] s:text-[12px] lg:text-[14px] xl2:text-[16px]">
                            ₹29,900
                          </span>
                          <span className="md:text-[13px] s:text-[10px] lg:text-[14px] xl2:text-[13px]">
                            (GST inclusive price)
                          </span>
                        </div>
                      </div>

                      <div className="text-center shadow-lg rounded mb-5">
                        <div className="overflow-hidden">
                          <img src={mono} alt="" className="" />
                        </div>

                        <div className="text-left m-3">
                          <p className="py-2 font-bold md:text-[20px] lg:text-[16px] s:text-[16px] xl2:text-[20px] ">
                            Monocrystalline Solar Panels (Mono-SI)
                          </p>
                          <p className="md:py-2 s:py-1 md:text-[13px] s:text-[11px] lg:text-[12px] xl2:text-[13px]">
                            Find the right products that fits your budget and
                            needs.
                          </p>
                          <div className="flex">
                            {" "}
                            <img
                              src={star}
                              alt=""
                              className="md:max-w-[100px] s:max-w-[80px] md:mb-2 s:mb-2 "
                            />{" "}
                            <span className="ml-2 text-bluee font-bold md:text-[16px] s:text-[12px] lg:text-[13px] xl2:text-[16px]">
                              11018
                            </span>{" "}
                          </div>
                          <span className="font-bold md:text-[16px] s:text-[12px] lg:text-[14px] xl2:text-[16px]">
                            ₹29,900
                          </span>
                          <span className="md:text-[13px] s:text-[10px] lg:text-[14px] xl2:text-[13px]">
                            (GST inclusive price)
                          </span>
                        </div>
                      </div>

                      <div className="text-center shadow-lg rounded mb-5">
                        <div className="overflow-hidden">
                          <img src={mono} alt="" className="" />
                        </div>

                        <div className="text-left m-3">
                          <p className="py-2 font-bold md:text-[20px] lg:text-[16px] s:text-[16px] xl2:text-[20px] ">
                            Monocrystalline Solar Panels (Mono-SI)
                          </p>
                          <p className="md:py-2 s:py-1 md:text-[13px] s:text-[11px] lg:text-[12px] xl2:text-[13px]">
                            Find the right products that fits your budget and
                            needs.
                          </p>
                          <div className="flex">
                            {" "}
                            <img
                              src={star}
                              alt=""
                              className="md:max-w-[100px] s:max-w-[80px] md:mb-2 s:mb-2 "
                            />{" "}
                            <span className="ml-2 text-bluee font-bold md:text-[16px] s:text-[12px] lg:text-[13px] xl2:text-[16px]">
                              11018
                            </span>{" "}
                          </div>
                          <span className="font-bold md:text-[16px] s:text-[12px] lg:text-[14px] xl2:text-[16px]">
                            ₹29,900
                          </span>
                          <span className="md:text-[13px] s:text-[10px] lg:text-[14px] xl2:text-[13px]">
                            (GST inclusive price)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default VendorCardDetail;
