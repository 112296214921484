import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getStorage, uploadBytes, getDownloadURL } from "firebase/storage";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDzcjynBpwD5OAuzP4uQC0AgkxyGcaKT-g",
  authDomain: "hargharsolarindore.org",
  databaseURL:
    "https://har-ghar-solar-indore-41023-default-rtdb.firebaseio.com",
  projectId: "har-ghar-solar-indore-41023",
  storageBucket: "har-ghar-solar-indore-41023.appspot.com",
  messagingSenderId: "294324480765",
  appId: "1:294324480765:web:7cfd48b7475fe88777ec94",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { uploadBytes, getDownloadURL, app, db, auth, messaging, storage };
