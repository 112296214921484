import React, { useEffect, useState } from "react";
import { CalculatorData } from "../../../../CalculatorData/CalculatorData";

const Calculator = () => {
  const [capacity, setCapacity] = useState(2);
  const [total, setTotal] = useState(120000);

  const increaseHandle = () => {
    if (capacity > 1 && capacity < 11) {
      setCapacity(capacity + 1);
      setTotal(total + 60000);
    }
  };

  const decreaseHandle = () => {
    if (capacity > 2) {
      setCapacity(capacity - 1);
      setTotal(total - 60000);
    }
  };

  const filteredData = CalculatorData.filter(
    (item) => item.capacity === capacity
  );

  useEffect(() => {}, [capacity]);

  return (
    <div className="w-[100%] h-full">
      <div className="flex justify-center items-center p-4 bg-[#235688] text-white font-semibold text-2xl">
        <p>Calculator</p>
      </div>
      <div className="flex flex-col justify-center items-center mt-[10%]">
        <p className="font-semibold md:text-xl s:text-base">
          Required Solar Panel Capacity in (kw)
        </p>
        <p className="flex space-x-10 md:mt-[2%] s:mt-[4%]">
          <p className="bg-blue-50 p-2 border border-black font-semibold">
            {capacity} kw
          </p>
          <p className="bg-blue-50 p-2 border border-black font-semibold">
            ₹{total}
          </p>
        </p>
        <div className=" md:mt-[2%] s:mt-[4%] flex space-x-2 mr-[2%]">
          <button onClick={decreaseHandle}>
            <i class="fa-solid fa-angle-left bg-blue-50 p-2 py-5"></i>
          </button>
          <button onClick={increaseHandle}>
            <i class="fa-solid fa-angle-right bg-blue-50 p-2 py-5"></i>
          </button>
        </div>
        <p>
          {filteredData.map((item, index) => {
            return (
              <div
                key={index}
                className="lg:text-sm md:text-xs md:py-[5%] md:px-[1%] lg:p-10"
              >
                <table className="table-auto border-collapse md:hidden s:block p-4 mt-5">
                  <tbody>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        Capacity in kW{" "}
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.capacity}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        Total Project Cost in Rs
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.totalCost}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        Subsidy Amount
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.subsidy}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        Net Price after Subsidy
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.netPrice}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        MONTHLY GENERATION (120 UNITS/ kW/MONTH )
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.monthlyGeneration}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        ANNUAL GENERATION
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.annualGeneration}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        ANNUAL SAVINGS RS. 7/UNIT
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.annualSaving}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black p-2 px-8 font-bold">
                        RETURN ON INVESTMENT (ROI) (IN YEARS)
                      </td>
                      <td className="border border-black p-2 px-4 font-medium">
                        {item.return}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table-auto border-collapse border border-black lg:w-full s:hidden md:block border-none">
                  <thead>
                    <tr>
                      <th className="border border-black px-4 py-2">
                        Capacity in kW
                      </th>
                      <th className="border border-black px-4 py-2">
                        Total Project Cost in Rs.
                      </th>
                      <th className="border border-black px-4 py-2">
                        Subsidy Amount
                      </th>
                      <th className="border border-black px-4 py-2">
                        Net Price after Subsidy
                      </th>
                      <th className="border border-black px-4 py-2">
                        MONTHLY GENERATIO N (120 UNITS/ kW/MONTH )
                      </th>
                      <th className="border border-black px-4 py-2">
                        ANNUAL GENERATION
                      </th>
                      <th className="border border-black px-4 py-2">
                        ANNUAL SAVINGS RS. 7/UNIT
                      </th>
                      <th className="border border-black px-4 py-2">
                        RETURN ON INVESTME NT (ROI) (IN YEARS)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black py-2 flex justify-center border-r-0 border-t-0 font-medium">
                        {item.capacity}
                      </td>
                      <td className="border border-black px-[2%] font-medium">
                        {item.totalCost}
                      </td>
                      <td className="border border-black px-[2%] font-medium">
                        {item.subsidy}
                      </td>
                      <td className="border border-black px-[3%] font-medium">
                        {item.netPrice}
                      </td>
                      <td className="border border-black px-[7%] font-medium">
                        {item.monthlyGeneration}
                      </td>
                      <td className="border border-black px-[4%] font-medium">
                        {item.annualGeneration}
                      </td>
                      <td className="border border-black px-[4%] font-medium">
                        {item.annualSaving}
                      </td>
                      <td className="border border-black px-[7%] font-medium">
                        {item.return}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default Calculator;
