import React, { useContext, useState } from "react";
import { ref, push, set } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../../../../../firebase";
import profile from "../../../../../Assests/Vendor/profile.png";
import product from "../../../../../Assests/Vendor/product.png";
import notificationVendor from "../../../../../Assests/Vendor/notificationVendor.png";
import Rectangle from "../../../../../Assests/Vendor/Rectangle.png";
import SRectangle from "../../../../../Assests/Vendor/SRectangle.png";
import AuthContext from "../../../../../Context/AuthContext";

const AddProduct = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [productImage, setProductImage] = useState("");
  const currentUser = localStorage.getItem("currentUser");
  const AuthCtx = useContext(AuthContext);
  const imageUrl = AuthCtx.vendorProfileImage;
  const productImages = AuthCtx.productImages;
  const navigate = useNavigate();

  console.log(productImages);
  const [vendorAddProduct, setVendorAddProduct] = useState({
    productName: "",
    productInfo: "",
    stockAvailability: "In stock",
    date: "",
  });

  const handleChange = (event) => {
    let name, value;
    name = event.target.name;
    if (event.target.type === "radio") {
      value = event.target.value === "In Stock" ? "In Stock" : "Out Stock";
    } else {
      value = event.target.value;
    }
    setVendorAddProduct({ ...vendorAddProduct, [name]: value });
  };

  const handleImageUpload = async (e, imageRef) => {
    console.log(imageRef);
    const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
    const file = e.target.files[0];
    const uploadDataRef = `${imageRef}/${file.name}`;
    console.log(uploadDataRef);
    await AuthCtx.handleProductsDatabaseStorage(uploadDataRef, file);

    // Fetch a specific image by filename
    const specificFilename = file.name;
    const specificImageUrl = await AuthCtx.fetchVendorImageByFilename(
      `vendor/${emailPath}/product`,
      specificFilename
    );

    setProductImage(specificImageUrl);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProductImage(file);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const emailPath = currentUser.split("@")[0].replace(/[.#$/[\]]/g, "_");
    const dataRef = ref(db, `vendor/${emailPath}/products`);
    const updatedVendorAddProduct = {
      ...vendorAddProduct,
      imageUrl: AuthCtx.productImages,
    };

    const uploaded = push(dataRef, updatedVendorAddProduct);
    const uniqueKey = uploaded.key;

    if (uploaded) {
      const imageUploadRef = `vendor/${emailPath}/product/${uniqueKey}`;
      await AuthCtx.handleProductsDatabaseStorage(
        `${imageUploadRef}/${productImage.name}`,
        productImage
      );

      // Get the download URL of the uploaded image
      const specificImageUrl = await AuthCtx.fetchVendorImageByFilename(
        `vendor/${emailPath}/product/${uniqueKey}`,
        productImage.name
      );

      console.log(specificImageUrl);

      // Update the data with the image URL
      const updatedData = {
        ...updatedVendorAddProduct,
        imageUrl: specificImageUrl,
      };

      // Set the updated data back to the database
      set(ref(db, `vendor/${emailPath}/products/${uniqueKey}`), updatedData);
    } else {
      console.log("data is not uploaded...");
    }

    setVendorAddProduct({
      productName: "",
      productInfo: "",
      stockAvailability: "",
      date: "",
    });

    AuthCtx.setProductImages(null);
    navigate("/vendorproductlist");
  };

  return (
    <div className="main flex">
      <div className="block lg:hidden absolute z-50 ml-5 mt-3">
        <button onClick={() => setShowSidebar(!showSidebar)}>
          <i className="fas fa-bars fa-xl"></i>
        </button>
      </div>
      <div
        className={`sidebar s:absolute lg:relative top-0 left-0 ${
          showSidebar ? "block" : "hidden"
        } lg:block s:w-[50%] s:h-[100%] md:w-[25%] md:h-[100vh]  z-40  s:bg-gray-200 lg:bg-white h-screen s:break-words lg:w-[15%] xl2:w-[17%]`}
      >
        <ul className="flex flex-col gap-y-5 pt-28 pl-6 ">
          <Link to="/vendorprofile">
            <li className="flex items-center gap-x-2 hover:text-white  hover:bg-[#235688]  ">
              <img
                src={profile}
                alt=""
                className="md:w-6 s:w-6 s:opacity-50 md:opacity-100 "
              />
              Profile
            </li>
          </Link>
          <li className="flex items-center gap-x-2 hover:text-white hover:bg-[#235688]  ">
            <img
              src={notificationVendor}
              alt=""
              className="md:w-6 s:w-6 s:opacity-50 md:opacity-100"
            />
            Notification
          </li>
          <li className="flex items-center gap-x-2 hover:text-white hover:bg-[#235688]  ">
            <img
              src={product}
              alt=""
              className="md:w-6 s:w-6 s:opacity-50 md:opacity-100"
            />
            Product
          </li>
          <div className="space-y-3 lg:hidden">
            <Link to="/vendorproductlist">
              <li className="flex items-center gap-x-2 text-sm s:-ml-2 hover:text-white hover:bg-[#235688]  ">
                <i className="fa-hand-point-left fa-solid" />
                Previous Product
              </li>
            </Link>
            <li className="flex items-center gap-x-2 s:-ml-2  text-sm hover:text-white hover:bg-[#235688]  ">
              <i className="fa-solid fa-cart-plus"></i>
              Add New Product
            </li>
            <li className="flex items-center gap-x-2 text-sm s:-ml-2 hover:text-white hover:bg-[#235688]  ">
              <i className="fa-solid fa-face-smile"></i>
              Review & Ratings
            </li>
          </div>

          <li className="flex items-center gap-x-2 break-words ">
            <p>&larr; Logout</p>
          </li>
        </ul>
      </div>

      <div className="content h-full s:w-[100%] lg:w-[85%] xl2:w-[83%]">
        <div className="navbar md:w-[100%] s:h-14 md:h-20 bg-white flex justify-end items items-center gap-x-2">
          <img className="md:w-5 s:w-5  md:block " src={profile} alt="" />
          <span className=" md:block s:text-1xl md:text-sm md:mr-5">
            Dhanajay Raj
          </span>
          <div className="bg-gray-400 md:w-8 md:h-8 s:w-6 s:h-6 rounded-full mr-14 md:block">
            <span className="ml-3 font-bold s:text-1 xl md:text-sm s:ml-2 md:ml-3">
              ?
            </span>
          </div>
        </div>

        <div className="middle-content bg-gray-300 w-full  xl2:p-[30px] lg:p-[20px] md:p-[20px] s:p-[20px] m:p-[20px] sm:p-[20px] md:h-[50%]">
          <div className="inner-content bg-white flex overflow-hidden">
            <div className="left-content p-1  border-r-2 border-solid border-gray-100 hidden lg:block ">
              <Link to="/vendorproductlist">
                <p className="mt-20 lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC] ">
                  Previous Product
                </p>
              </Link>
              <p className="mt-5 lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC]">
                Add New Product
              </p>
              <p className="mt-5 lg:text-sm xl2:text-[16px] hover:bg-[#235688] hover:text-white p-2 text-[#232121CC]">
                Review And Rating
              </p>
            </div>
            <div className="right-content ">
              <div className="Review-Rating  border-solid   md:p-6 s:p-2 mt-3">
                <p className="text-[#152C5B] font-semibold xl2:text-xl md:text-xl md:ml-2 xl2:ml-10 s:ml-8 m:ml-14 sm:ml-16">
                  Add New Product
                </p>
              </div>

              <div className="Images-Section   border-solid grid  s:grid-col md:grid-cols-2 md:p-0 s:p-4 ">
                <div className=" m:ml-5 md:ml-0 sm:ml-9">
                  <div className="column-2 md:ml-8 s:ml-4">
                    <img
                      src={productImage ? productImage : Rectangle}
                      alt=""
                      className="xl2:max-w-[330px] lg:max-w-[250px]  md:max-w-[280px]  sm:max-w-[220px] m:max-w-[200px] s:max-w-[170px]"
                    />
                  </div>

                  <div className="imge flex gap-5  xl2:ml-36 xl2:mt-9 lg:-ml-6 lg:mt-8 md:mt-8  md:-ml-4 s:mt-8  s:ml-6 ">
                    <img
                      src={SRectangle}
                      alt=""
                      className=" xl2:w-[60px] xl2:h-[60px] xl2:-ml-24 lg:ml-16 md:ml-20  lg:w-[40px] lg:h-[40px] md:w-[60px] md:h-[60px] s:w-[60px] s:h-[60px] "
                    />
                    {/* <img
                        src={Imagenot}
                        alt=""
                        className=" xl2:w-[10%] xl2:h-[10%] lg:w-[15%] lg:h-[10%] bg-gray-200 md:w-[15%] md:h-[10%] s:w-[15%] s:h-[10%]"
                      /> */}

                    <input
                      id="ImageInput"
                      type="file"
                      accept="image/*"
                      className="lg:text-[12px] xl2:text-[14px] s:text-xs"
                      onChange={handleFileChange}
                      required
                    ></input>
                  </div>
                  <div>
                    <div className=" s:w-[250px] sm:w-[350px] md:w-[330px]  xl2:w-[423px] xl2:h-[84px] m-2 md:ml-6 m:ml-0 sm:-ml-10 s:ml-0 mt-3">
                      <span className="break-words s:text-sm md:text-base lg:text-sm xl2:text-base">
                        I hope this message finds you well. I am writing to
                        kindly request your assistance in submitting three
                        images as part of our ongoing project.
                      </span>
                    </div>
                  </div>
                </div>

                <div className="column-3 s:mt-8 md:mt-0  ">
                  <div className="form  md:ml-5 lg:ml-5 xl2:ml-0">
                    <form onSubmit={handleFormSubmit}>
                      <label>
                        <p>Product Name</p>
                        <input
                          type="text"
                          name="productName"
                          placeholder="HPS Hydro Consultants Pvt. Ltd."
                          className="bg-gray-200 lg:w-[270px] xl2:w-[270px] md:w-[250px] mt-2"
                          value={vendorAddProduct.productName}
                          onChange={handleChange}
                          required
                        />
                      </label>{" "}
                      <br /> <br />
                      <label>
                        <p>Product Info.</p>
                        <textarea
                          name="productInfo"
                          id=""
                          className="bg-gray-200 resize-none lg:w-[270px] md:w-[250px] mt-2"
                          value={vendorAddProduct.productInfo}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>{" "}
                      <br />
                      <br />
                      <label>Availability</label>
                      <br />
                      <label>
                        <input
                          type="radio"
                          required
                          name="stockAvailability"
                          value="In Stock"
                          className="mr-2 mt-2"
                          checked={
                            vendorAddProduct.stockAvailability === "In Stock"
                          }
                          onChange={handleChange}
                        />
                        In Stock
                      </label>
                      <label className="ml-10">
                        <input
                          type="radio"
                          name="stockAvailability"
                          value="Out Stock"
                          className="mr-2 mt-2"
                          required
                          checked={
                            vendorAddProduct.stockAvailability === "Out Stock"
                          }
                          onChange={handleChange}
                        />
                        Out Stock
                      </label>
                      <br />
                      <br />
                      <label>Date</label>
                      <br />
                      <input
                        type="date"
                        name="date"
                        className="bg-gray-200 mt-2"
                        value={vendorAddProduct.date}
                        onChange={handleChange}
                        required
                      />
                      <br /> <br />
                      <button
                        // onClick={handleFormSubmit}
                        className="bg-bluee hover:bg-bluee text-white font-bold py-2 px-5 "
                      >
                        Complete
                      </button>
                      <br />
                      <br />
                      <button className="bg-gray-300 hover:bg-gray-700 text-blue-900 font-bold py-2 px-5 xl2:mb-5">
                        Add New Product
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
